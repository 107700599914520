import React, { useCallback, useMemo } from 'react';
import { useCurrentScale } from 'remotion';
import { SELECTION_OUTLINE_COLOR } from '../../constants';

const HANDLE_SIZE = 8;

export const ResizeHandle: React.FC<{
  positions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>;
  updatePositions: (newPositions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>) => void;
  type: 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'top-left';
  outlineRef: React.RefObject<HTMLDivElement>;
  setIsDragging: (isDragging: boolean) => void;
  onHandleRelease: (newPositions: Pick<React.CSSProperties, 'left' | 'top' | 'width' | 'height'>) => void;
}> = ({ positions, updatePositions, type, outlineRef, setIsDragging, onHandleRelease }) => {
  const scale = useCurrentScale();
  const size = Math.round(HANDLE_SIZE / scale);
  const borderSize = 1 / scale;

  const sizeStyle: React.CSSProperties = useMemo(() => {
    return {
      position: 'absolute',
      height: size,
      width: size,
      backgroundColor: 'white',
      border: `${borderSize}px solid ${SELECTION_OUTLINE_COLOR}`
    };
  }, [borderSize, size]);

  const margin = -size / 2 - borderSize;

  const style: React.CSSProperties = useMemo(() => {
    if (type === 'top') {
      return {
        ...sizeStyle,
        marginTop: margin,
        left: '50%',
        marginLeft: -size / 2,
        cursor: 'ns-resize'
      };
    }

    if (type === 'top-right') {
      return {
        ...sizeStyle,
        marginTop: margin,
        marginRight: margin,
        right: 0,
        cursor: 'nesw-resize'
      };
    }

    if (type === 'right') {
      return {
        ...sizeStyle,
        marginRight: margin,
        right: 0,
        top: '50%',
        marginTop: -size / 2,
        cursor: 'ew-resize'
      };
    }

    if (type === 'bottom-right') {
      return {
        ...sizeStyle,
        marginBottom: margin,
        marginRight: margin,
        right: 0,
        bottom: 0,
        cursor: 'nwse-resize'
      };
    }

    if (type === 'bottom') {
      return {
        ...sizeStyle,
        marginBottom: margin,
        bottom: 0,
        left: '50%',
        marginLeft: -size / 2,
        cursor: 'ns-resize'
      };
    }

    if (type === 'bottom-left') {
      return {
        ...sizeStyle,
        marginBottom: margin,
        marginLeft: margin,
        bottom: 0,
        cursor: 'nesw-resize'
      };
    }

    if (type === 'left') {
      return {
        ...sizeStyle,
        marginLeft: margin,
        top: '50%',
        marginTop: -size / 2,
        cursor: 'ew-resize'
      };
    }
    if (type === 'top-left') {
      return {
        ...sizeStyle,
        marginLeft: margin,
        marginTop: margin,
        cursor: 'nwse-resize'
      };
    }

    throw new Error('Unknown type: ' + JSON.stringify(type));
  }, [margin, size, sizeStyle, type]);

  const onPointerDown = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (e.button !== 0) {
        return;
      }

      const initialX = e.clientX;
      const initialY = e.clientY;
      const parentDimensions = outlineRef.current?.parentElement?.getBoundingClientRect();
      const parentHeight = parentDimensions?.height || 0;
      const parentWidth = parentDimensions?.width || 0;

      let width = positions.width;
      let height = positions.height;
      let left = positions.left;
      let top = positions.top;

      const onPointerMove = (pointerMoveEvent: PointerEvent) => {
        const offsetY = pointerMoveEvent.clientY - initialY;
        const offsetX = pointerMoveEvent.clientX - initialX;

        const isLeft = type === 'top-left' || type === 'left' || type === 'bottom-left';
        const isTop = type === 'top-left' || type === 'top' || type === 'top-right';
        const isRight = type === 'top-right' || type === 'right' || type === 'bottom-right';
        const isBottom = type === 'bottom-left' || type === 'bottom' || type === 'bottom-right';

        const relativeChangeInX = (offsetX / parentWidth) * 100;
        const relativeChangeInY = (offsetY / parentHeight) * 100;

        let newWidth = parseFloat(positions.width as string);
        let newHeight = parseFloat(positions.height as string);
        let newLeft = parseFloat(positions.left as string);
        let newTop = parseFloat(positions.top as string);

        if (isLeft) {
          const maxDecrease = newWidth - 1;
          const actualDecrease = Math.min(relativeChangeInX, maxDecrease);
          newWidth -= actualDecrease;
          newLeft += actualDecrease;
        } else if (isRight) {
          const maxIncrease = 100 - newLeft - newWidth;
          newWidth += Math.min(relativeChangeInX, maxIncrease);
        }

        if (isTop) {
          const maxDecrease = newHeight - 1;
          const actualDecrease = Math.min(relativeChangeInY, maxDecrease);
          newHeight -= actualDecrease;
          newTop += actualDecrease;
        } else if (isBottom) {
          const maxIncrease = 100 - newTop - newHeight;
          newHeight += Math.min(relativeChangeInY, maxIncrease);
        }

        width = `${Math.max(1, Math.round(newWidth))}%`;
        height = `${Math.max(1, Math.round(newHeight))}%`;
        left = `${Math.max(0, Math.min(99, Math.round(newLeft)))}%`;
        top = `${Math.max(0, Math.min(99, Math.round(newTop)))}%`;

        updatePositions({
          top,
          left,
          width,
          height
        });
      };

      const onPointerUp = () => {
        setIsDragging(false);
        onHandleRelease({
          width,
          height,
          left,
          top
        });
        window.removeEventListener('pointermove', onPointerMove);
      };

      window.addEventListener('pointermove', onPointerMove, { passive: true });

      window.addEventListener('pointerup', onPointerUp, {
        once: true
      });
    },
    [positions, updatePositions, outlineRef, type, setIsDragging]
  );

  return <div onPointerDown={onPointerDown} style={style} />;
};
