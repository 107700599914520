import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { IconDotsVertical } from '@tabler/icons-react';
import { MediaContentResponse } from '@goldcast/api/content';
import { MenuItemsLabelType } from '../AssetsTable/AssetsTableUtils';
import { classnames } from '@/libs/utils';

const variantStyles = {
  default: {
    listItemStyle: 'px-3 py-2',
    iconSize: 19,
    menuWidth: 'w-56',
    iconContainerSize: 'w-8 h-8'
  },
  compact: {
    listItemStyle: 'py-1.5 px-2 text-xs w-40',
    iconSize: 14,
    iconContainerSize: 'w-4 h-4',
    menuWidth: 'w-40'
  }
};
interface RowMenuItemProps {
  menuItems: { label: string; icon: any }[];
  onAction: (actionType: MenuItemsLabelType) => void;
  content: MediaContentResponse | null;
  positionVariant?: 'top-right' | 'bottom-right' | 'top-left' | '';
  forceShow?: boolean;
  variant?: 'compact' | 'default';
  menuContainerStyle?: string;
}

export default function RowMenuItem({
  menuItems,
  onAction,
  content,
  positionVariant = 'top-right',
  forceShow = false,
  variant = 'default',
  menuContainerStyle = ''
}: RowMenuItemProps) {
  const { listItemStyle, iconSize, menuWidth, iconContainerSize } = variantStyles[variant];

  return (
    <div className="relative flex justify-end" onClick={e => e.stopPropagation()}>
      <Menu as="div" className="relative">
        <Menu.Button>
          <div
            className={classnames(
              '-mr-0.5 flex items-center justify-center rounded-lg  text-black opacity-0 hover:bg-slate-200 group-hover:opacity-100',
              iconContainerSize,
              {
                'opacity-100': forceShow
              }
            )}
          >
            <IconDotsVertical size={iconSize} stroke={1.5} />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classnames(
              'absolute z-50 rounded-lg border bg-white p-1 text-sm text-black shadow-lg',
              menuContainerStyle,
              menuWidth,
              {
                'right-8 top-0': positionVariant === 'top-right',
                'bottom-0 right-8': positionVariant === 'bottom-right',
                'right-0 top-8': positionVariant === 'top-left'
              }
            )}
          >
            {menuItems?.map(
              item =>
                ((item.label === 'Generate Social Post' && content?.multi_modal_enabled) ||
                  item.label !== 'Generate Social Post') && (
                  <Menu.Item as="div" key={item.label}>
                    <button
                      className={classnames(
                        'flex w-full items-center space-x-3 rounded-md hover:bg-slate-100',
                        listItemStyle
                      )}
                      onClick={() => onAction(item.label as MenuItemsLabelType)}
                    >
                      <item.icon size={18} stroke={1.5} />
                      <span>{item.label}</span>
                    </button>
                  </Menu.Item>
                )
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
