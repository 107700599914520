import { memo, useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { Transition } from '@headlessui/react';
import { TranscriptSelection, TranscriptSelectionRanges } from '../types';
import { getEditTranscriptDuration, getSelectionRanges } from '../utils';
import {
  getDeletesFromSelectedRanges,
  getSelectionPartitionedDeletes,
  getWordsBoundaries,
  mergeDeletes
} from './utils';
import { CLIP_MIN_DURATION, CLIP_MIN_DURATION_ERROR } from '../constants';
import TranscriptHideMenuOptions from './TranscriptHideMenuOptions';
import IconButton from '@/components/atoms/Button/IconButton';
import { isEmptyObject } from '@/libs/utils';
import { DeletedRange, updateClipCaptionsLoadingState, updateDeletes, updateStartEndTime } from '@/stores/clip';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import TooltipV2 from '@/components/TooltipV2';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { ClipDeletes } from '@/domains/asset';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';

function EditTranscriptMenu({
  show,
  editPopupRef,
  isCreatingClip,
  transcriptSelection,
  onClose,
  onCreate,
  onPlay,
  onStartEditing
}: {
  show: boolean;
  editPopupRef: React.MutableRefObject<HTMLDivElement | null>;
  isCreatingClip?: boolean;
  transcriptSelection?: TranscriptSelection | null;
  onClose: () => void;
  onCreate: (start: number, end: number) => void;
  onPlay?: (time: number, startPlaying?: boolean) => void;
  onStartEditing: () => void;
}) {
  const { transcript } = useTranscriptContext();
  const { clipId, clipData, clipCaptionsLoading } = useClipsContext();
  const { createNewChapter } = useTranscriptPage();
  const [isDeletingWords, setIsDeletingWords] = useState<boolean | undefined>(undefined);
  const [isNewChapterLoading, setIsNewChapterLoading] = useState<boolean>(false);
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCorrectTranscriptEnabled = featureFlags[FeatureFlagKeys.Use_CL_Edit_Full_Recording_Correct];
  const isChapterizationEnabled = featureFlags[FeatureFlagKeys.Use_CL_Chapterization];

  useEffect(() => {
    if (isDeletingWords && !clipCaptionsLoading) {
      onClose();
      setIsDeletingWords(false);
    }
  }, [isDeletingWords, clipCaptionsLoading, onClose]);

  useEffect(() => {
    if (transcriptSelection?.words?.length === 1) {
      onPlay?.(transcriptSelection?.words[0]?.start_time - clipData.asset_metadata.start);
    }
  }, [transcriptSelection?.words]);

  const selectionRanges: TranscriptSelectionRanges | null = useMemo(() => {
    if (!transcriptSelection?.words?.length) return null;
    // Get selection ranges (active and deleted ranges) when words are selected, used to delete/restore words
    return getSelectionRanges(transcriptSelection.words, clipData.asset_metadata.deletes);
  }, [clipData.asset_metadata.deletes, transcriptSelection?.words]);

  const isDeleteOnlySelection = useMemo(() => {
    return selectionRanges && !selectionRanges.active.length;
  }, [selectionRanges]);

  const isActiveOnlySelection = useMemo(() => {
    return selectionRanges && !selectionRanges.deleted.length;
  }, [selectionRanges]);

  const isCreateClipDisabled = useMemo(() => {
    if (!transcriptSelection?.words || !selectionRanges?.active.length) return true;
    const start = selectionRanges.active[0].start;
    const end = selectionRanges.active.at(-1)?.end || 0;
    return end - start < CLIP_MIN_DURATION;
  }, [selectionRanges?.active, transcriptSelection?.words]);

  const selectionDuration = useMemo(() => {
    if (!transcriptSelection?.words || !selectionRanges) return 0;
    if (isDeleteOnlySelection) {
      return (transcriptSelection.words.at(-1)?.end_time || 0) - (transcriptSelection.words[0].start_time || 0);
    }
    return selectionRanges.active.reduce((acc, range) => {
      return acc + range.end - range.start;
    }, 0);
  }, [isDeleteOnlySelection, selectionRanges, transcriptSelection?.words]);

  const formattedDuration = useMemo(() => getEditTranscriptDuration(selectionDuration), [selectionDuration]);

  const isDeleteDisabled = useMemo(() => {
    if (!transcriptSelection?.words || !selectionRanges?.active.length || !selectionDuration) return true;

    return (clipData.asset_metadata?.duration || 0) - selectionDuration < 5;
  }, [
    clipData.asset_metadata?.duration,
    selectionDuration,
    selectionRanges?.active.length,
    transcriptSelection?.words
  ]);

  const deleteSelectedRanges = useCallback(() => {
    if (!selectionRanges?.active.length) return;
    const newDeletes = getDeletesFromSelectedRanges(selectionRanges);
    // Update start if needed
    if (clipData.asset_metadata.captions[0].start_time === selectionRanges?.active[0].start) {
      const newFirstWord = clipData.asset_metadata.captions.find(
        w => w.start_time > selectionRanges?.active[selectionRanges?.active.length - 1].end
      );
      updateStartEndTime(clipId, 'start', newFirstWord?.start_time);
    }
    // Update end if needed
    if (
      clipData.asset_metadata.captions.at(-1)?.end_time ===
      selectionRanges?.active[selectionRanges?.active.length - 1].end
    ) {
      const newLastWord = clipData.asset_metadata.captions.findLast(
        w => w.end_time <= selectionRanges?.active[0].start
      );
      updateStartEndTime(clipId, 'end', newLastWord?.end_time);
    }
    // Update clip
    updateClipCaptionsLoadingState(clipId, true);
    const updatedDeletes = { ...clipData.asset_metadata.deletes, ...newDeletes };
    updateDeletes(clipId, mergeDeletes(updatedDeletes));
    setIsDeletingWords(true);
  }, [clipData.asset_metadata.captions, clipData.asset_metadata.deletes, clipId, selectionRanges]);

  const playFromTime = useCallback(() => {
    if (transcriptSelection?.words.length && clipId) {
      onPlay?.(transcriptSelection?.words[0]?.start_time - clipData.asset_metadata.start, true);
      onClose();
    }
  }, [clipData.asset_metadata.start, clipId, onClose, onPlay, transcriptSelection?.words]);

  const restoreDeletedWords = useCallback(() => {
    const words = transcriptSelection?.words;
    if (!words || words.length === 0) return;

    const { firstWord, lastWord } = getWordsBoundaries(words);

    const partitionedDeletes = getSelectionPartitionedDeletes(firstWord, lastWord, clipData.asset_metadata.deletes);

    if (isEmptyObject(partitionedDeletes.restoring)) return;
    const restoringDeletesKeys = Object.keys(partitionedDeletes.restoring);
    const firstRestoringDelete: DeletedRange = partitionedDeletes.restoring[restoringDeletesKeys[0]];
    const lastRestoringDelete: DeletedRange =
      partitionedDeletes.restoring[restoringDeletesKeys[restoringDeletesKeys.length - 1]];

    const newDeleteRanges: ClipDeletes = {};

    if (selectionRanges && selectionRanges.deleted[0].start < clipData.asset_metadata.start) {
      updateStartEndTime(clipId, 'start', selectionRanges.deleted[0].start);
    }

    if (selectionRanges && selectionRanges.deleted[0].end > clipData.asset_metadata.end) {
      updateStartEndTime(clipId, 'end', selectionRanges.deleted[0].end);
    }

    // Add remainings from the start as deleted, if there are any
    if (firstWord.start_time > firstRestoringDelete.bounds[0]) {
      newDeleteRanges[firstRestoringDelete.bounds[0]] = {
        prev_end_time: firstRestoringDelete.prev_end_time,
        next_start_time: firstWord.start_time,
        bounds: [firstRestoringDelete.bounds[0], firstWord.start_time]
      };
    }
    // Add remainings from the end as deleted, if there are any
    if (transcript[lastWord.index + 1]?.start_time < lastRestoringDelete.bounds[1]) {
      newDeleteRanges[transcript[lastWord.index + 1]?.start_time] = {
        prev_end_time: lastWord.end_time,
        next_start_time: lastRestoringDelete.next_start_time,
        bounds: [transcript[lastWord.index + 1]?.start_time, lastRestoringDelete.bounds[1]]
      };
    }

    // Update deletes
    updateClipCaptionsLoadingState(clipId, true);
    updateDeletes(
      clipId,
      { ...partitionedDeletes.keeping, ...newDeleteRanges },
      { eventName: 'FULL_RECORDING_TRANSCRIPT_RESTORE' }
    );
    setIsDeletingWords(true);
  }, [
    clipData.asset_metadata.deletes,
    clipData.asset_metadata.end,
    clipData.asset_metadata.start,
    clipId,
    selectionRanges,
    transcript,
    transcriptSelection?.words
  ]);

  const createClip = useCallback(() => {
    if (!selectionRanges?.active.length) return;
    onCreate(selectionRanges.active.at(0)!.start, selectionRanges.active.at(-1)!.end);
  }, [onCreate, selectionRanges?.active]);

  const onNewChapter = useCallback(async () => {
    const start = selectionRanges?.active?.[0]?.start;
    if (!start) return;

    setIsNewChapterLoading(true);
    try {
      await createNewChapter('New Chapter', start);
    } finally {
      setIsNewChapterLoading(false);
    }
  }, [selectionRanges]);

  return (
    <Transition
      appear
      show={show}
      className="absolute left-[33rem] top-0 z-20 flex h-full w-12 items-center"
      enter="transition duration-600 ease-out"
      enterFrom="transform translate-x-[10px] opacity-0"
      enterTo="transform translate-x-0 opacity-100"
      leave="transition duration-100 ease-out"
      leaveFrom="transform translate-x-0 opacity-100"
      leaveTo="transform translate-x-[10px] opacity-0"
    >
      <div className="absolute z-30">
        <div className="top-0 flex h-full w-12 shrink-0 items-center justify-center">
          <div
            className="flex flex-col items-center justify-center rounded-lg border bg-white p-1.5 shadow"
            ref={editPopupRef}
          >
            <div className="mb-1 w-max rounded-lg bg-black p-1 text-center text-xs text-white">{formattedDuration}</div>
            {isDeleteOnlySelection ? (
              <TooltipV2
                place="left"
                tooltipId="restore-tooltip"
                tooltipContent="Restore"
                size="xsmall"
                tooltipElement={
                  <IconButton
                    icon={clipCaptionsLoading ? 'IconLoader2' : 'IconRestore'}
                    trackingId="icon-delete-text"
                    onClick={restoreDeletedWords}
                    variation="checkbox"
                    disabled={clipCaptionsLoading}
                    iconClassName={clipCaptionsLoading ? 'animate-spin' : ''}
                    buttonClassName="w-10 h-10"
                    data-tooltip-id="restore-tooltip"
                  />
                }
              />
            ) : (
              <>
                <TooltipV2
                  place="left"
                  tooltipId="play-video-tooltip"
                  tooltipContent="Play video"
                  size="xsmall"
                  tooltipElement={
                    <IconButton
                      icon="IconPlayerPlay"
                      trackingId="icon-play-full-recording"
                      onClick={playFromTime}
                      variation="checkbox"
                      buttonClassName="w-10 h-10"
                      data-tooltip-id="play-video-tooltip"
                    />
                  }
                />
                <TooltipV2
                  place="left"
                  tooltipId="create-clip-tooltip"
                  tooltipContent={isCreateClipDisabled ? CLIP_MIN_DURATION_ERROR : 'Create clip'}
                  size="xsmall"
                  tooltipElement={
                    <IconButton
                      icon={isCreatingClip ? 'IconLoader2' : 'IconMovie'}
                      trackingId="icon-create-clip"
                      onClick={createClip}
                      disabled={isCreateClipDisabled || isCreatingClip}
                      variation="checkbox"
                      iconClassName={isCreatingClip ? 'animate-spin' : ''}
                      buttonClassName="w-10 h-10"
                      data-tooltip-id="create-clip-tooltip"
                    />
                  }
                />

                {isCorrectTranscriptEnabled &&
                  isActiveOnlySelection &&
                  transcriptSelection?.isSingleParagraphSelection && (
                    <TooltipV2
                      place="left"
                      tooltipId="correct-transcript-tooltip"
                      tooltipContent="Correct"
                      size="xsmall"
                      tooltipElement={
                        <IconButton
                          icon="IconEdit"
                          trackingId="icon-correct-transcript"
                          onClick={onStartEditing}
                          variation="checkbox"
                          disabled={clipCaptionsLoading}
                          buttonClassName="w-10 h-10"
                          data-tooltip-id="correct-transcript-tooltip"
                        />
                      }
                    />
                  )}

                {isChapterizationEnabled && (
                  <TooltipV2
                    place="left"
                    tooltipId="new-chapter-tooltip"
                    tooltipContent="New Chapter"
                    size="xsmall"
                    tooltipElement={
                      <IconButton
                        icon="IconList"
                        trackingId="icon-new-chapter"
                        onClick={onNewChapter}
                        variation="checkbox"
                        disabled={isNewChapterLoading}
                        buttonClassName="w-10 h-10"
                        data-tooltip-id="new-chapter-tooltip"
                      />
                    }
                  />
                )}

                {isCorrectTranscriptEnabled && isActiveOnlySelection && (
                  <TranscriptHideMenuOptions transcriptSelection={transcriptSelection} onCloseMenu={onClose} />
                )}

                <TooltipV2
                  place="left"
                  tooltipId="delete-tooltip"
                  tooltipContent={isDeleteDisabled ? CLIP_MIN_DURATION_ERROR : 'Delete'}
                  size="xsmall"
                  tooltipElement={
                    <IconButton
                      icon={clipCaptionsLoading ? 'IconLoader2' : 'IconTrash'}
                      trackingId="icon-delete-text"
                      onClick={deleteSelectedRanges}
                      variation="checkbox"
                      disabled={isDeleteDisabled || clipCaptionsLoading}
                      iconClassName={clipCaptionsLoading ? 'animate-spin' : ''}
                      buttonClassName="w-10 h-10"
                      data-tooltip-id="delete-tooltip"
                    />
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default memo(EditTranscriptMenu);
