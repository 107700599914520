import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { brandTemplateRetrieve } from '@goldcast/api/content';
import { useQuery } from 'react-query';
import { pick, isEqual } from 'radash';
import SpeakersTimelineZoom from './SpeakersTimelineZoom';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import SpeakersTimelinePlaybackControls from './SpeakersTimelinePlaybackControls';
import ClipSpeakersTimelineView from './ClipSpeakersTimelineView';
import { PreviewTemplate } from '../../SideBar/types';
import { mapBrandedTemplatesResponse, propertiesToCompare } from '../../SideBar/Templates/utils';
import Button from '@/components/atoms/Button/Button';
import PlayerToggle from '@/Pages/TranscriptPage/PlayerControls/PlayerToggle';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { resetFullClipChanges } from '@/libs/fullRecordingEdit';
import usePlayerControls from '@/Pages/TranscriptPage/PlayerControls/usePlayerControls';
import { isEmptyObject, removeUndefinedKeys } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useIsScrollingElement from '@/hooks/useIsScrollingElement';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { showErrorToast } from '@/libs/toast/toast';

const DEFAULT_TEMPLATE_ID = '79bc58a3-3c5b-48a6-88b3-007095d2b752';

function ClipSpeakersTimeline({
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  currentSection,
  setCurrentSectionAndPlay
}: {
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  currentSection: ClipPlayerSection;
  setCurrentSectionAndPlay: (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => void;
}) {
  const { clipId, clipData } = useClipsContext();
  const { isTimelineCollapsed } = usePlayerControls();
  const transcriptStore = useTranscriptContext();
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const cuePointerRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isResetChangesDialogOpen,
    openDialog: openResetChangesDialog,
    closeDialog: closeResetChangesDialog
  } = useDialog();

  const [zoomValue, setZoomValue] = useState(1);
  const { isScrolling, handleOnScroll } = useIsScrollingElement();
  const [defaultClipTemplate, setDefaultClipTemplate] = useState<PreviewTemplate | undefined>(undefined);

  const { isError: templateFetchError } = useQuery({
    queryKey: [DEFAULT_TEMPLATE_ID],
    queryFn: () => brandTemplateRetrieve({ id: DEFAULT_TEMPLATE_ID }),
    onSuccess: data => {
      const results = mapBrandedTemplatesResponse(clipId, [data]);
      setDefaultClipTemplate(results[0]);
    }
  });

  function resetFullRecordingChanges() {
    const fullDuration = Math.max(
      transcriptStore.transcript.at(-1)?.end_time || transcriptStore.transcript.at(-1)?.start_time || 0,
      mainPlayerProps.mainSectionRef.current?.duration || 0
    );
    resetFullClipChanges(clipId, fullDuration, defaultClipTemplate);
    closeResetChangesDialog();
  }

  useEffect(() => {
    if (!mainPlayerProps.mainSectionRef.current) return;
    const dispatchComputeFrame = () => {
      EventBus.dispatch(CustomEvents.ComputeFrame);
    };
    mainPlayerProps.mainSectionRef.current.addEventListener('seeked', dispatchComputeFrame);
    return () => {
      mainPlayerProps.mainSectionRef.current?.removeEventListener('seeked', dispatchComputeFrame);
    };
  }, []);

  useEffect(() => {
    if (templateFetchError) {
      showErrorToast('Failed to fetch template data');
    }
  }, [templateFetchError]);

  const isClipMetadataDifferentFromTemplate = useMemo(() => {
    if (!defaultClipTemplate) return false;

    const templateProperties = removeUndefinedKeys(pick(defaultClipTemplate, propertiesToCompare));
    const clipProperties = removeUndefinedKeys(pick(clipData.asset_metadata, propertiesToCompare));
    return !isEqual(templateProperties, clipProperties);
  }, [defaultClipTemplate, clipData.asset_metadata]);

  const isResetChangesVisible = useMemo(() => {
    return (
      !isEmptyObject(clipData.asset_metadata.deletes || {}) ||
      !isEmptyObject(clipData.asset_metadata.edits || {}) ||
      !!clipData.asset_metadata.merges?.length ||
      !!clipData.asset_metadata.intro ||
      !!clipData.asset_metadata.outro ||
      isClipMetadataDifferentFromTemplate
    );
  }, [
    clipData.asset_metadata.deletes,
    clipData.asset_metadata.edits,
    clipData.asset_metadata.merges?.length,
    clipData.asset_metadata.intro,
    clipData.asset_metadata.outro,
    isClipMetadataDifferentFromTemplate
  ]);

  useEffect(() => {
    if (isScrolling) return;
    if (!cuePointerRef.current || !timelineContainerRef.current) return;

    const cuePointerDistance =
      cuePointerRef.current.getBoundingClientRect().left - timelineContainerRef.current.clientWidth;
    if (cuePointerDistance > 0 && cuePointerDistance < 20) {
      cuePointerRef.current.scrollIntoView({ inline: 'start' });
    }
  }, [mainPlayerProps.mainSectionCurrentTime, isScrolling]);

  return (
    <div
      className="mb-3 w-full shrink-0 overflow-x-auto overflow-y-hidden rounded-2xl bg-white px-1 py-3 shadow-lg"
      ref={timelineContainerRef}
      onScroll={handleOnScroll}
    >
      <div className="sticky left-0 mb-3 grid w-full grid-cols-3 justify-between px-1">
        <div className="flex h-9 w-72 cursor-pointer items-center justify-start gap-4 rounded-sm">
          {isResetChangesVisible && (
            <Button
              variation="filled"
              buttonSize="small"
              trackingId="reset-full-edit-changes"
              onClick={openResetChangesDialog}
            >
              Revert changes
            </Button>
          )}
        </div>
        <SpeakersTimelinePlaybackControls
          currentSection={currentSection}
          introPlayerProps={introPlayerProps}
          outroPlayerProps={outroPlayerProps}
          mainPlayerProps={mainPlayerProps}
          setCurrentSectionAndPlay={setCurrentSectionAndPlay}
        />
        <div className="flex justify-end gap-4">
          <SpeakersTimelineZoom zoomValue={zoomValue} onChange={setZoomValue} />
          <PlayerToggle />
        </div>
      </div>
      <ClipSpeakersTimelineView
        currentSection={currentSection}
        introPlayerProps={introPlayerProps}
        outroPlayerProps={outroPlayerProps}
        mainPlayerProps={mainPlayerProps}
        cuePointerRef={cuePointerRef}
        visible={!isTimelineCollapsed}
        setCurrentSectionAndPlay={setCurrentSectionAndPlay}
        zoom={zoomValue}
      />
      <ConfirmationDialog
        isOpen={isResetChangesDialogOpen}
        onClose={closeResetChangesDialog}
        onConfirm={resetFullRecordingChanges}
        title="Revert Changes"
        content="Are you sure you want to revert to the original version of this video? Your current changes will be lost."
        confirmLabel="Revert Changes"
      />
    </div>
  );
}

export default memo(ClipSpeakersTimeline);
