export const NUMBER_OF_COLUMNS_IN_FILMSTRIP = 5;
export const NUMBER_OF_ROWS_IN_FILMSTRIP = 6;
export const TIME_DIFFERENCE_X_AXIS = 0.5;
export const TIME_DIFFERENCE_Y_AXIS = 2.5;
export const MAX_TIME_IN_1_IMAGE = 15;
export const MINIMUM_FRAME_DURATION = 3;
export const FRAME_HEIGHT_IN_PX = 80;
export const LANDSCAPE_WIDTH_IN_PX = (FRAME_HEIGHT_IN_PX * 16) / 9;
export const INFINITE_SCROLL_RATE = 2;
export const CHAPTER_TOOLTIP_THRESHOLD = 6;
export const CHAPTER_DRAG_SENSITIVITY = 1.5;
