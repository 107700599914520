import { ChapterListResponseChapterGenerationStatusEnum, ChapterResponse } from '@goldcast/api/content';
import { Speaker } from '@/domains/speaker';
import { Word, WordType } from '@/domains/transcript';

export interface SpeakerWithDetails extends Omit<Speaker, 'face_ids'> {
  id: string;
  key: string;
  color: string;
  bgColor: string;
  speakingSlots: Record<string, number>;
  video: {
    url: string;
    start: number;
  };
  parent_id: string | null;
  face_ids?: number[];
}

export type TranscriptMap = Record<string, { url: string; start: number }>;

export enum ChapterGenerationStatus {
  DONE = 'DONE',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
  PROCESSING = 'PROCESSING'
}

export interface TranscriptContextType {
  transcript: Word[];
  end: number;
  speakersWithDetails: Record<string, SpeakerWithDetails>;
  usedSpeakers: SpeakerWithDetails[];
  trimming: boolean;
  setTrimming?: (trimming: boolean) => void;
  chapterData: ChapterResponse[] | undefined;
  chapterGenerationStatus: ChapterListResponseChapterGenerationStatusEnum;
  timeSrtIndexArray: {
    startTime: number;
    srtIndex: number;
  }[];
  transcriptMap: TranscriptMap;
}

export interface RawWord {
  start_time: number;
  end_time: number;
  type: WordType;
  speaker_label: string;
  srt_index: number;
  alternatives: { content: string }[];
}

export type TranscriptContextData = Omit<TranscriptContextType, 'trimming' | 'setTrimming'>;
