import { getEnv } from '@/libs/env';

export enum ApplicationMode {
  Production = 'production',
  Preprod = 'preprod',
  Development = 'development'
}

const configs = {
  APP_AMPLIFY_CONFIG_OVERRIDES: {
    AWS_COGNITO_IDENTITY_POOL_ID: getEnv('AWS_COGNITO_IDENTITY_POOL_ID'),
    AWS_COGNITO_REGION: getEnv('AWS_COGNITO_REGION'),
    AWS_PROJECT_REGION: getEnv('AWS_PROJECT_REGION'),
    AWS_USER_FILES_S3_BUCKET: getEnv('AWS_USER_FILES_S3_BUCKET'),
    AWS_USER_FILES_S3_BUCKET_REGION: getEnv('AWS_USER_FILES_S3_BUCKET_REGION'),
    AWS_USER_POOLS_ID: getEnv('AWS_USER_POOLS_ID'),
    AWS_USER_POOLS_WEB_CLIENT_ID: getEnv('AWS_USER_POOLS_WEB_CLIENT_ID')
  },
  APP_AUTH_COOKIE_DOMAIN: getEnv('APP_AUTH_COOKIE_DOMAIN'),
  APP_BACKEND_SERVER: getEnv('APP_BACKEND_SERVER'),
  LOG_ROCKET_ENABLED: getEnv('LOG_ROCKET_ENABLED'),
  LOG_ROCKET_APP_ID: getEnv('LOG_ROCKET_APP_ID'),
  FONTS_S3_URL: getEnv('FONTS_S3_URL'),
  API_URL: getEnv('API_URL'),
  VOD_URL: getEnv('VOD_URL'),
  CLOUDFRONT_ASSET_URL: getEnv('CLOUDFRONT_ASSET_URL'),
  VISION_URL: getEnv('VISION_URL'),
  CLOUDFRONT_STATIC_ASSET_URL: getEnv('CLOUDFRONT_STATIC_ASSET_URL'),
  FILESTACK_API_KEY: getEnv('FILESTACK_API_KEY'),
  FILESTACK_BUCKET_PATH: getEnv('FILESTACK_BUCKET_PATH'),
  S3_STATIC_ASSETS_BUCKET: getEnv('S3_STATIC_ASSETS_BUCKET'),
  STATIC_ASSETS_CDN: getEnv('STATIC_ASSETS_CDN'),
  AWS_STORE_REGION: getEnv('AWS_STORE_REGION'),
  SPEAKER_VIDEO_URL: getEnv('SPEAKER_VIDEO_URL'),
  APP_HARNESS_SECRET: getEnv('APP_HARNESS_SECRET'),
  THUMBNAIL_ROOT_FOLDER: getEnv('THUMBNAIL_ROOT_FOLDER'),
  CUSTOM_UPLOAD_URL: getEnv('CUSTOM_UPLOAD_URL'),
  MODE: getEnv('MODE') as ApplicationMode,
  VUE_APP_ASSETS_CDN: getEnv('VUE_APP_ASSETS_CDN'),
  PUSHER_APP_KEY: getEnv('PUSHER_APP_KEY'),
  PUSHER_APP_CLUSTER: getEnv('PUSHER_APP_CLUSTER'),
  STRIPE_PUBLISHABLE_KEY: getEnv('STRIPE_PUBLISHABLE_KEY'),
  CONTENT_LAB_BUILD_NUMBER: getEnv('CONTENT_LAB_BUILD_NUMBER'),
  APP_COOKIES_TO_LOCAL_STORAGE_ENABLED: getEnv('APP_COOKIES_TO_LOCAL_STORAGE_ENABLED')
};

export function getEnvConfig(name: keyof typeof configs) {
  return configs[name];
}
