import { pick } from 'radash';
import { roundToNDecimalPlaces } from './utils';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';
import {
  saveClipChanges,
  trackClipUpdate,
  updateClipCaptionsLoadingState,
  updateClipMetadataWithId
} from '@/stores/clip';
import { propertiesToCompare } from '@/Pages/Clip/SideBar/Templates/utils';
import { Clip } from '@/domains/asset';

export function resetFullClipChanges(
  videoAssetId: string,
  recordingDuration: number,
  template: PreviewTemplate | undefined
) {
  updateClipCaptionsLoadingState(videoAssetId, true);
  const duration = roundToNDecimalPlaces(recordingDuration, 3);
  const updatedClipMetaData: Partial<Clip['asset_metadata']> = {
    intro: undefined,
    outro: undefined,
    deletes: {},
    excludes: [],
    edits: {},
    merges: [],
    start: 0,
    end: duration,
    duration: duration
  };

  if (template) {
    updatedClipMetaData.template_id = template.id;
    Object.entries(pick(template, propertiesToCompare)).forEach(([key, value]) => {
      updatedClipMetaData[key] = value;
    });
  }

  updateClipMetadataWithId(videoAssetId, updatedClipMetaData);
  saveClipChanges(videoAssetId, false, true, false);
  trackClipUpdate(videoAssetId, 'RESET_FULL_RECORDING_CHANGES');
}
