import React, { memo, RefObject, useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { Transition } from '@headlessui/react';
import {
  ClipPlayerSection,
  useIntroRefReturn,
  useMainSectionRefReturn,
  useOutroRefReturn
} from '../ClipPlayerSections/ClipPlayerSectionsTypes';
import { convertSecondsToTimeString } from '../../SideBar/ClipDuration/utils';
import VideoSection from '../ClipTimeline/VideoSection';
import { LANDSCAPE_WIDTH_IN_PX } from '../ClipTimeline/ClipTimelineConstants';
import AddNewSection from '../ClipTimeline/AddNewSection';
import useClipTranscriptPlayPause from '../ClipPlayerControls/useClipTranscriptPlayPause';
import ClipSpeakersTimelineTrim from './ClipSpeakersTimelineTrim';
import ClipSpeakersTimelineDeletes from './ClipSpeakersTimelineDeletes';
import ClipSpeakersTimelineSpeakers from './ClipSpeakersTimelineSpeakers';
import ChaptersView from '../ClipTimeline/ChaptersView';
import { classnames, roundToNDecimalPlaces } from '@/libs/utils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { updateIntroOutro } from '@/stores/clip';
import { isDeleted } from '@/context/TranscriptContext/TranscriptContextUtils';
import { editFullRecording } from '@/stores/editFullRecording';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

function ClipSpeakersTimelineView({
  visible,
  zoom,
  outroPlayerProps,
  mainPlayerProps,
  introPlayerProps,
  currentSection,
  cuePointerRef,
  setCurrentSectionAndPlay
}: {
  visible: boolean;
  zoom: number;
  outroPlayerProps: useOutroRefReturn;
  mainPlayerProps: useMainSectionRefReturn;
  introPlayerProps: useIntroRefReturn;
  currentSection: ClipPlayerSection;
  cuePointerRef: RefObject<HTMLDivElement>;
  setCurrentSectionAndPlay: (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => void;
}) {
  const { clipId, clipData, clipCaptionsLoading } = useClipsContext();
  const transcriptStore = useTranscriptContext();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isFullRecordingTrimEnabled = featureFlags[FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim];
  const isChapterizationEnabled = featureFlags[FeatureFlagKeys.Use_CL_Chapterization];

  const timelineRef = useRef<HTMLDivElement>(null);
  const timeHoverAxis = useRef<HTMLDivElement>(null);
  const timeSectionHoverAxis = useRef<HTMLDivElement>(null);
  const timelineContainerRef = useRef<HTMLDivElement>(null);

  const editFullRecordingStore = useSyncExternalStore(editFullRecording.subscribe, editFullRecording.getSnapshot);
  const isDragging = useMemo(() => editFullRecordingStore.isDragging, [editFullRecordingStore.isDragging]);

  const [endDragHandleX, setEndDragHandleX] = useState(0);
  const [startDragHandleX, setStartDragHandleX] = useState(0);

  const clipMetadata = useMemo(() => clipData.asset_metadata, [clipData.asset_metadata]);
  const chapters = useMemo(() => transcriptStore.chapterData, [transcriptStore.chapterData]);
  const chapterGenerationStatus = useMemo(
    () => transcriptStore.chapterGenerationStatus,
    [transcriptStore.chapterGenerationStatus]
  );

  useEffect(() => {
    if (timeHoverAxis.current && timeSectionHoverAxis.current) {
      timeHoverAxis.current.style.left = '0';
      timeSectionHoverAxis.current.style.left = '0';
    }

    if (!cuePointerRef.current) return;
    setTimeout(() => {
      cuePointerRef.current?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
    }, 500);
  }, [zoom]);

  const fullRecordingDuration = useMemo(() => {
    return mainPlayerProps.mainSectionRef.current?.duration || 0;
  }, [mainPlayerProps.mainSectionRef.current?.duration]);

  const getTimelinePositionFromTime = useCallback(
    (time: number, { position }: { position: 'start' | 'end' }) => {
      if (timelineContainerRef.current) {
        const rect = timelineContainerRef.current.getBoundingClientRect();
        const perc = (time / fullRecordingDuration) * 100;
        const mouseX = (perc / 100) * rect.width;
        return position === 'end' ? rect.width - mouseX : mouseX;
      }
      return 0;
    },
    [fullRecordingDuration]
  );

  useEffect(() => {
    if (!fullRecordingDuration) return;
    setTimeout(() => {
      setStartDragHandleX(
        Math.max(getTimelinePositionFromTime(clipData.asset_metadata.start, { position: 'start' }), 0)
      );
      setEndDragHandleX(Math.max(getTimelinePositionFromTime(clipData.asset_metadata.end, { position: 'end' }), 0));
    }, 500);
  }, [
    clipData.asset_metadata.start,
    getTimelinePositionFromTime,
    clipData.asset_metadata.end,
    fullRecordingDuration,
    zoom,
    clipData.asset_metadata.intro,
    clipData.asset_metadata.outro
  ]);

  const { isPaused } = useClipTranscriptPlayPause({
    currentSection,
    introPlayerProps,
    mainPlayerProps,
    outroPlayerProps
  });

  const mainSectionCurrentTime = useMemo(() => {
    return roundToNDecimalPlaces(mainPlayerProps.mainSectionCurrentTime, 3);
  }, [mainPlayerProps.mainSectionCurrentTime]);

  const setTimeFromTimeline = useCallback(
    (e: React.MouseEvent, isDragging: boolean = false) => {
      if (!timelineRef.current) return;
      const x = e.clientX;
      const { left } = timelineRef.current.getBoundingClientRect();
      const pos = (x - left) / timelineRef.current.offsetWidth;
      const time = fullRecordingDuration * pos;
      if (!isDragging && (isDeleted(time, clipId) || time < clipMetadata.start || time > clipMetadata.end)) {
        return;
      }
      setCurrentSectionAndPlay('main', !isPaused, time - clipMetadata.start);
      EventBus.dispatch(CustomEvents.TimelineChanged, { time });
    },
    [fullRecordingDuration, clipId, clipMetadata.start, clipMetadata.end, setCurrentSectionAndPlay, isPaused]
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!timelineRef.current || !timeHoverAxis.current || !timeSectionHoverAxis.current) return 0;
      const x = e.clientX;
      const { left, width } = timelineRef.current.getBoundingClientRect();
      timeHoverAxis.current.style.left = `${x - left}px`;
      timeSectionHoverAxis.current.style.left = `${x - left}px`;
      const time = ((x - left) / width) * fullRecordingDuration;
      timeSectionHoverAxis.current.innerText = convertSecondsToTimeString(
        time,
        time >= 3600 ? 'HH:mm:ss.SS' : 'mm:ss.SS'
      );
    },
    [fullRecordingDuration]
  );

  const removeSection = useCallback(
    (section: 'intro' | 'outro') => {
      updateIntroOutro(clipId, section, {
        url: undefined,
        duration: 0
      });
    },
    [clipId]
  );

  const onSeeked = useCallback(
    (section: ClipPlayerSection, t: number) => {
      setCurrentSectionAndPlay(section, !isPaused, t);
    },
    [isPaused, setCurrentSectionAndPlay]
  );

  const onSelectChapter = useCallback(
    (time: number) => {
      setCurrentSectionAndPlay('main', !isPaused, time);
      EventBus.dispatch(CustomEvents.TimelineChanged, { time });
    },
    [isPaused, setCurrentSectionAndPlay]
  );

  return (
    <Transition
      className="flex min-h-[5rem]"
      style={{
        width: `${100 * zoom}%`
      }}
      as="div"
      show={visible}
      enter="transition duration-500 ease-out"
      enterFrom="transform translate-y-[50px] opacity-0"
      enterTo="transform translate-y-0 opacity-100"
      leave="transition duration-300 ease-out"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform translate-y-[50px] opacity-0"
    >
      <div className={classnames('z-[51] flex aspect-video items-center px-2', { 'mt-auto': !!clipMetadata.intro })}>
        {clipMetadata.intro ? (
          <VideoSection
            section={'intro'}
            singleFrameWidth={LANDSCAPE_WIDTH_IN_PX}
            removeSection={removeSection}
            src={clipMetadata.intro}
            progressPercent={(introPlayerProps.introCurrentTime * 100) / introPlayerProps.introDuration}
            showProgress={currentSection === 'intro'}
            onSeeked={t => onSeeked('intro', t * introPlayerProps.introDuration)}
            duration={introPlayerProps.introDuration}
          />
        ) : (
          <AddNewSection position="left" index={0} classNames="!w-14 h-full" />
        )}
      </div>
      <div className="relative flex w-full flex-col justify-end">
        <div className="relative pb-2">
          {isChapterizationEnabled && chapters && (
            <ChaptersView
              chapters={chapters}
              chapterGenerationStatus={chapterGenerationStatus}
              totalDuration={fullRecordingDuration}
              zoom={zoom}
              onSelectChapter={onSelectChapter}
            />
          )}
        </div>
        <div
          onClick={setTimeFromTimeline}
          onMouseMove={onMouseMove}
          className={classnames(
            'group relative grid w-full grid-cols-[1fr] grid-rows-[max-content,1fr] self-end rounded-xl transition-all duration-500',
            {
              'max-h-0 overflow-hidden': !visible
            }
          )}
          id="timeline"
          data-testid="timeline"
          ref={timelineRef}
        >
          <div
            className={classnames(
              'invisible absolute top-[-22px] z-50 translate-x-[-50%] rounded-md bg-black px-1 py-0.5 text-white group-hover:visible',
              isDragging ? 'text-xs' : 'text-2xs'
            )}
            ref={timeSectionHoverAxis}
            data-testid="timeline-hover"
          />
          {!isDragging && !clipCaptionsLoading && (
            <>
              <div
                className="pointer-events-none absolute z-20 h-full w-[2px] bg-black"
                style={{ left: (mainSectionCurrentTime / fullRecordingDuration) * 100 + '%' }}
                ref={cuePointerRef}
              >
                <div className="-translate-x-[6.5px] -translate-y-[16px]">
                  <IconPlayerPlayFilled size={15} className="rotate-90" />
                </div>
              </div>
            </>
          )}

          <div
            className="pointer-events-none invisible absolute z-50 h-full w-[1px] bg-slate-400 group-hover:visible"
            ref={timeHoverAxis}
          />
          <div
            className="no-scrollbar relative max-h-40 w-full overflow-y-auto overflow-x-hidden rounded-xl pb-2"
            ref={timelineContainerRef}
          >
            <div
              className="absolute top-0 z-[49] flex w-full"
              style={{ height: timelineContainerRef.current?.scrollHeight || '100%' }}
            >
              <div
                className="z-10 h-full cursor-not-allowed rounded-l-xl bg-slate-300 bg-opacity-70 backdrop-blur-sm"
                style={{ width: `${startDragHandleX}px` }}
              />
              <div
                className={classnames('h-full cursor-pointer border border-black', {
                  'rounded-e-xl': endDragHandleX === 0,
                  'rounded-s-xl': startDragHandleX === 0
                })}
                style={{ width: `calc(100% - (${startDragHandleX + endDragHandleX}px) )` }}
              />
              <div
                className="h-full w-[33%] cursor-not-allowed rounded-r-xl bg-slate-300/70 backdrop-blur-sm"
                style={{ width: `${endDragHandleX}px` }}
              />
            </div>

            <ClipSpeakersTimelineSpeakers
              fullRecordingDuration={fullRecordingDuration}
              startDragHandleX={startDragHandleX}
            />
          </div>
          <ClipSpeakersTimelineDeletes fullRecordingDuration={fullRecordingDuration} />
          {isFullRecordingTrimEnabled && (
            <ClipSpeakersTimelineTrim
              timelineContainerRef={timelineContainerRef}
              startDragHandleX={startDragHandleX}
              endDragHandleX={endDragHandleX}
              mainPlayerProps={mainPlayerProps}
              setStartDragHandleX={setStartDragHandleX}
              setEndDragHandleX={setEndDragHandleX}
              onDrag={setTimeFromTimeline}
            />
          )}
        </div>
      </div>
      <div className={classnames('z-[51] flex aspect-video items-center px-2', { 'mt-auto': !!clipMetadata.outro })}>
        {clipMetadata.outro ? (
          <VideoSection
            section={'outro'}
            singleFrameWidth={LANDSCAPE_WIDTH_IN_PX}
            removeSection={removeSection}
            src={clipMetadata.outro}
            progressPercent={(outroPlayerProps.outroCurrentTime * 100) / outroPlayerProps.outroDuration}
            showProgress={currentSection === 'outro'}
            onSeeked={t => onSeeked('outro', t * outroPlayerProps.outroDuration)}
            duration={outroPlayerProps.outroDuration}
          />
        ) : (
          <AddNewSection
            position="right"
            index={1}
            classNames="!w-14 h-full"
            popoverClassNames="w-full h-full right-6 bottom-6"
          />
        )}
      </div>
    </Transition>
  );
}

export default memo(ClipSpeakersTimelineView);
