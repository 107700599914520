import { useSyncExternalStore, useState } from 'react';
import { assetsChaptersCreate, assetsChaptersPartialUpdate, ChapterResponse } from '@goldcast/api/content';
import { ClipDeletes, ClipEdits, ClipMetadata, SourceType } from '@/domains/asset';
import useAnalytics from '@/hooks/useAnalytics';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { createContentClip } from '@/libs/clipContentUtil';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { prependAllClips, setClipGeneratingCount } from '@/stores/clip';
import { core } from '@/stores/core';
import { addUnseenClipIds } from '@/stores/unseenClips';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useAppContext } from '@/context/AppContext/AppContext';
import { loadChapters } from '@/context/TranscriptContext/TranscriptContextUtils';

export default function useTranscriptPage() {
  const [selectedChapter, setSelectedChapter] = useState<ChapterResponse | null>(null);

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const transcriptStore = useTranscriptContext();

  const { incrementClipsUsed } = useFreeTrialHook();
  const { trackContentCreated } = useAnalytics();
  const { logger } = useAppContext();

  async function saveClip(
    { start, end, deletes, edits }: Pick<ClipMetadata, 'start' | 'end' | 'deletes' | 'edits'>,
    source: SourceType
  ) {
    try {
      const newClip = await createContentClip(start, end, source, deletes, edits);
      prependAllClips([{ ...newClip, isRemoved: false } as any]);
      addUnseenClipIds([newClip.id]);
      if (!coreStore.content?.is_sample_upload) {
        incrementClipsUsed(1);
      }
      trackContentCreated({
        asset: newClip,
        state: 'Created',
        source: 'Manual'
      });
      return newClip;
    } catch (err: any) {
      trackContentCreated({
        source: 'Manual',
        state: 'Failed',
        failureReason: err
      });
      showErrorToast("Creating the clip didn't quite work out. Double-check the time range and give it another shot.");
    }
  }

  const createClip = (
    start,
    end,
    { edits, deletes }: { edits: ClipEdits; deletes: ClipDeletes } = { edits: {}, deletes: {} }
  ) => {
    setClipGeneratingCount(1);
    return saveClip(
      {
        start,
        end,
        edits,
        deletes
      },
      'USER'
    ).then(_newClip => {
      setClipGeneratingCount(0);
    });
  };

  const updateChapter = (chapterId: string, title?: string, startTime?: number) => {
    const body: { title?: string; start_time?: number } = {};
    if (title !== undefined) body.title = title;
    if (startTime !== undefined) body.start_time = startTime;

    return assetsChaptersPartialUpdate({
      id: chapterId,
      body
    })
      .then(() => {
        const updatedChapters = transcriptStore?.chapterData?.map(chapter =>
          chapter.id === chapterId
            ? {
                ...chapter,
                ...(title !== undefined && { title }),
                ...(startTime !== undefined && { start_time: startTime })
              }
            : chapter
        );
        EventBus.dispatch(CustomEvents.UpdateChapters, updatedChapters);
      })
      .catch(err => {
        showErrorToast('Failed to update chapter. Please try again in a few minutes.');
        logger.error('Failed to update chapter', err);
      });
  };

  const createNewChapter = async (title: string, chapterStartTime: number) => {
    if (!coreStore.content?.id) {
      return;
    }

    return assetsChaptersCreate({
      body: {
        content_id: coreStore.content?.id,
        start_time: chapterStartTime,
        title
      }
    })
      .then(async () => {
        const updatedChapters = await loadChapters(coreStore.content?.id ?? '');
        EventBus.dispatch(CustomEvents.UpdateChapters, updatedChapters?.chapters);
        showSuccessToast('Chapter created successfully');
      })
      .catch(err => {
        showErrorToast('Failed to create chapter. Please try again in few minutes.');
        logger.error('Failed to create chapter', err);
      });
  };

  return {
    createClip,
    saveClip,
    updateChapter,
    createNewChapter,
    selectedChapter,
    setSelectedChapter,
    isAudio: coreStore.content?.media_type === 'AUDIO'
  };
}
