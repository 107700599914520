import { memo, useState } from 'react';
import { TEMPLATES_POPUP_TABS, TemplatesPopupTabs } from './constants';
import TemplatesList from '../../SideBar/Templates/TemplatesList';
import { ALL_TEMPLATES_LIMIT } from '../../constants';
import { classnames } from '@/libs/utils';

function ClipCustomizerTemplatesPopup({ isFullRecordingEdit = false }: { isFullRecordingEdit?: boolean }) {
  const [selectedTab, setSelectedTab] = useState(TemplatesPopupTabs.Preset);

  return (
    <div className="absolute -top-28 right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-visible rounded-lg border bg-white px-4 shadow">
      <div className="sticky top-0 z-[15] bg-white">
        <div className="py-3">
          <div className="text-lg font-medium">Templates</div>
          <div className="text-sm text-slate-600">Get started with one click</div>
        </div>
        <div className="flex items-center">
          {TEMPLATES_POPUP_TABS.map(tab => {
            const isSelectedTab = selectedTab === tab.name;
            return (
              <div key={tab.name} className="w-full px-4 py-2 text-center" onClick={() => setSelectedTab(tab.name)}>
                <div
                  className={classnames(
                    'cursor-pointer border-b-2 p-1.5 text-sm font-medium',
                    isSelectedTab ? 'border-b-black' : 'border-b-transparent'
                  )}
                >
                  {tab.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="overflow-auto px-1 py-2">
        {TEMPLATES_POPUP_TABS.map(tab => {
          const isSelectedTab = selectedTab === tab.name;
          return (
            <div key={tab.name} className={isSelectedTab ? 'visible' : 'hidden'}>
              <TemplatesList
                limit={ALL_TEMPLATES_LIMIT}
                queryParams={{ has_organization: tab.showDefaultOnly }}
                isInsidePopover={true}
                isFullRecordingEdit={isFullRecordingEdit}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(ClipCustomizerTemplatesPopup);
