import { CSSProperties } from 'react';
import { FPS_24 } from '../constants';
import { Page } from '../types';
import { getFontFamilyStyle } from './font';
import { getActiveCaptionsBackgroundColor, getInactiveCaptionsOpacityClass } from '@/stores/clip';
import { CaptionStyle, Clip } from '@/domains/asset';
import { getColorWithOpacity } from '@/components/atoms/ColorPicker/utils';
import { INACTIVE_WORD_OPACITY } from '@/Pages/Clip/CanvasPlayer/constants';

export const DEFAULT_FONT_SIZE = 48;

export function getCaptionPages({ clip }: { clip: Clip }): Page[] {
  let pages: Page[] = [];

  const captions = clip.asset_metadata.captions || [];

  if (!captions.length) {
    return pages;
  }

  const fontFamily = getFontFamilyStyle(clip.id, clip.asset_metadata.font_location);

  let tokens: Page['tokens'] = [];

  let currentPageIndex = captions[0].srt_index;

  for (let i = 0; i < captions.length; i += 1) {
    const word = captions[i];
    if (word.srt_index !== currentPageIndex) {
      if (tokens.length) {
        const fromFrame = tokens[0].fromFrame || 0;

        pages.push({
          text: word.content,
          tokens,
          fromFrame,
          toFrame: fromFrame,
          ...getStylesForPage({ clip, fontFamily: fontFamily || 'Inter' })
        });
        tokens = [];
      }

      currentPageIndex = word.srt_index;
    }

    if (!word.hidden) {
      tokens.push({
        text: word.content,
        fromFrame: Math.ceil((word.processed_start_time || 0) * FPS_24),
        toFrame: Math.ceil((word.processed_end_time || 0) * FPS_24)
      });
    }
  }

  if (tokens.length) {
    const fromFrame = tokens[0].fromFrame || 0;

    pages.push({
      text: captions[captions.length - 1].content,
      tokens,
      fromFrame,
      toFrame: fromFrame,
      ...getStylesForPage({ clip, fontFamily: fontFamily || 'Inter' })
    });
  }

  pages = pages.map((page, pageIndex) => {
    const pageText = page.tokens.map(token => token.text).join(' ');

    const nextPage = pages[pageIndex + 1] ?? null;
    const toFrame = nextPage
      ? nextPage.fromFrame
      : Math.round((clip.asset_metadata.end - clip.asset_metadata.start) * FPS_24);

    return {
      ...page,
      text: pageText,
      toFrame
    };
  });

  return pages;
}

function getStylesForPage({
  clip,
  fontFamily
}: {
  clip: Clip;
  fontFamily: string;
}): Pick<Page, 'style' | 'activeTokenStyle' | 'inactiveTokenStyle' | 'groupedTokensStyle'> {
  const highlightType = clip.asset_metadata?.highlight_type;
  const textColor = clip.asset_metadata?.magicLayout?.textColor;

  const activeTextColor =
    highlightType !== 'none'
      ? clip.asset_metadata.text_highlight_color || '#ffffff'
      : clip.asset_metadata?.magicLayout?.textColor;

  const activeWordStyles = {
    backgroundColor: getActiveCaptionsBackgroundColor(clip.asset_metadata, highlightType),
    opacity: 1
  };
  const inactiveWordStyles = {
    backgroundColor: 'transparent',
    opacity: getInactiveCaptionsOpacityClass(clip.asset_metadata, highlightType)
  };

  if (highlightType === 'text') {
    activeWordStyles.opacity = 1;
    inactiveWordStyles.opacity = 0.6;
  }

  const isBackgroundApplied = clip.asset_metadata.caption_styles?.animation === CaptionStyle.Background;
  const captionsBackgroundColor = !isBackgroundApplied
    ? null
    : getColorWithOpacity(clip.asset_metadata.caption_styles?.background_style_color, INACTIVE_WORD_OPACITY);

  let activeAnimationStyles: CSSProperties = {};
  let inactiveAnimationStyles: CSSProperties = {};

  const animation = clip.asset_metadata.caption_styles?.animation;
  const strokeWidth = 1.2;
  const blurRadius = 0.7;
  const outlineColor = '#000';
  const captionStyles =
    animation === CaptionStyle.Outline
      ? {
          textShadow: `-${strokeWidth}px -${strokeWidth}px ${blurRadius}px ${outlineColor}, ${strokeWidth}px -${strokeWidth}px ${blurRadius}px ${outlineColor}, -${strokeWidth}px ${strokeWidth}px ${blurRadius}px ${outlineColor}, ${strokeWidth}px ${strokeWidth}px ${blurRadius}px ${outlineColor}, -${strokeWidth}px -${strokeWidth}px ${blurRadius}px ${outlineColor}, ${strokeWidth}px -${strokeWidth}px ${blurRadius}px ${outlineColor}, -${strokeWidth}px ${strokeWidth}px ${blurRadius}px ${outlineColor}, ${strokeWidth}px ${strokeWidth}px ${blurRadius}px ${outlineColor}`
        }
      : {};

  if (highlightType === 'box') {
    activeAnimationStyles = {};
    inactiveAnimationStyles = captionStyles;
  } else {
    activeAnimationStyles = inactiveAnimationStyles = captionStyles;
  }

  const clipMetadata = clip.asset_metadata;
  const captionPosition = clipMetadata?.caption_positions;

  const fontSize =
    clipMetadata.caption_styles?.font_size || (clipMetadata.caption_styles?.scale || 1) * DEFAULT_FONT_SIZE;

  const commonContainerStyles: CSSProperties = {
    display: 'flex',
    fontFamily,
    fontSize,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  };

  let left = captionPosition?.x ?? '2.5%';
  let top = captionPosition?.y ?? '82%';
  let width = captionPosition?.width ?? '95%';
  let height = captionPosition?.height ?? '18%';

  return {
    style: {
      ...commonContainerStyles,
      position: 'absolute',
      left: typeof left !== 'string' ? left + '%' : left,
      top: typeof top !== 'string' ? top + '%' : top,
      width: typeof width !== 'string' ? width + '%' : width,
      height: typeof height !== 'string' ? height + '%' : height,
      textTransform: clipMetadata.caption_styles?.caps ? 'uppercase' : 'none'
    },
    groupedTokensStyle: {
      ...commonContainerStyles,
      columnGap: fontSize / 3 + 'px',
      lineHeight: fontSize * 1.5 + 'px',
      ...(captionsBackgroundColor && {
        background: captionsBackgroundColor
      })
    },
    activeTokenStyle: {
      color: activeTextColor,
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      ...activeWordStyles,
      ...activeAnimationStyles
    },
    inactiveTokenStyle: {
      color: textColor,
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      ...inactiveWordStyles,
      ...inactiveAnimationStyles
    }
  };
}
