import { CaptionStyle, CaptionsPlacement } from '@/domains/asset';

export enum CaptionSize {
  Small = 'Small ',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'X - Large',
  SXLarge = 'Super X - Large',
  UltraXLarge = 'Ultra X - Large',
  MegaXLarge = 'Mega X - Large'
}

export const CAPTION_SIZE_DEFAULT_OPTION = {
  value: 1,
  label: CaptionSize.Medium
};

export const CAPTION_SIZE_OPTIONS = [
  {
    value: 0.7,
    label: CaptionSize.Small
  },
  CAPTION_SIZE_DEFAULT_OPTION,
  {
    value: 1.3,
    label: CaptionSize.Large
  },
  {
    value: 1.6,
    label: CaptionSize.XLarge
  }
];

export const CAPTION_HIGHLIGHT_OPTIONS = [
  {
    value: 'none',
    label: 'None'
  },
  {
    value: 'text',
    label: 'Text Highlight'
  },
  {
    value: 'box',
    label: 'Box Highlight'
  }
];

export const CAPTION_ANIMATION_STYLE_OPTIONS = [
  { label: CaptionStyle.Basic },
  { label: CaptionStyle.Outline },
  { label: CaptionStyle.Background }
];

export const CAPTIONS_PLACEMENT_OPTIONS = [
  {
    value: CaptionsPlacement.Top,
    next: CaptionsPlacement.Middle
  },
  {
    value: CaptionsPlacement.Middle,
    next: CaptionsPlacement.Bottom
  },
  {
    value: CaptionsPlacement.Bottom,
    next: CaptionsPlacement.Top
  }
];

export function getNextCaptionsPlacement(current: CaptionsPlacement | undefined): CaptionsPlacement {
  const currentPlacement = CAPTIONS_PLACEMENT_OPTIONS.find(o => o.value === current);
  return currentPlacement?.next || CaptionsPlacement.Top;
}
