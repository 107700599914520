import createStore from './store';
import { currentClip, saveClipChanges, updateClipCaptionsLoadingState, updateClipMetadataWithId } from './clip';
import { Clip, ClipMetadata } from '@/domains/asset';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

interface ClipHistory {
  index: number;
  states: Pick<
    Clip['asset_metadata'],
    | 'start'
    | 'end'
    | 'edits'
    | 'deletes'
    | 'merges'
    | 'magicLayout'
    | 'title'
    | 'subtitle'
    | 'layout'
    | 'captions'
    | 'size'
    | 'intro'
    | 'outro'
    | 'font_location'
    | 'highlight_type'
    | 'word_highlight_color'
    | 'is_edited'
    | 'template_id'
    | 'caption_positions'
    | 'caption_styles'
    | 'hide_borders'
    | 'text_highlight_color'
  >[];
}

export const clipHistory = createStore<Record<string, ClipHistory>>({});

export function initHistory(clipId: string, clipMetadata: ClipMetadata) {
  const {
    start,
    end,
    edits,
    deletes,
    merges,
    magicLayout,
    title,
    subtitle,
    captions,
    size,
    layout,
    intro,
    outro,
    font_location,
    highlight_type,
    word_highlight_color,
    is_edited,
    template_id,
    caption_positions,
    hide_borders,
    caption_styles,
    text_highlight_color
  } = clipMetadata;
  clipHistory.update(data => ({
    ...data,
    [clipId]: {
      index: 0,
      states: [
        {
          start,
          end,
          edits,
          deletes,
          merges,
          magicLayout,
          title,
          subtitle,
          captions,
          layout,
          size,
          intro,
          outro,
          font_location,
          highlight_type,
          word_highlight_color,
          is_edited,
          template_id,
          caption_positions,
          caption_styles,
          hide_borders,
          text_highlight_color
        }
      ]
    } as ClipHistory
  }));
}

export function updateHistory(clipId: string, clipMetadata: ClipMetadata) {
  clipHistory.update((data: Record<string, ClipHistory>) => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      states: [
        ...data[clipId].states.slice(0, data[clipId].index + 1),
        {
          start: clipMetadata.start,
          end: clipMetadata.end,
          edits: clipMetadata.edits,
          deletes: clipMetadata.deletes,
          merges: clipMetadata.merges,
          magicLayout: clipMetadata.magicLayout,
          title: clipMetadata.title,
          subtitle: clipMetadata.subtitle,
          captions: clipMetadata.captions,
          size: clipMetadata.size,
          layout: clipMetadata.layout,
          intro: clipMetadata.intro,
          outro: clipMetadata.outro,
          font_location: clipMetadata.font_location,
          highlight_type: clipMetadata.highlight_type,
          word_highlight_color: clipMetadata.word_highlight_color,
          is_edited: clipMetadata.is_edited,
          template_id: clipMetadata.template_id,
          caption_positions: clipMetadata.caption_positions,
          caption_styles: clipMetadata.caption_styles,
          hide_borders: clipMetadata.hide_borders,
          text_highlight_color: clipMetadata.text_highlight_color
        }
      ],
      index: data[clipId].index + 1
    }
  }));
}

export function undoChange(clipId: string) {
  const history = clipHistory.getSnapshot()[clipId];
  const newHistoryIndex = history.index - 1;
  applyUndoRedo(clipId, history, newHistoryIndex);
}

export function redoChange(clipId: string) {
  const history = clipHistory.getSnapshot()[clipId];
  const newHistoryIndex = history.index + 1;
  applyUndoRedo(clipId, history, newHistoryIndex);
}

function applyUndoRedo(clipId: string, _clipHistory: ClipHistory, newHistoryIndex: number) {
  const currentClipValue = currentClip.getSnapshot()[clipId];
  updateClipCaptionsLoadingState(clipId, true);
  const {
    asset_metadata: {
      start,
      end,
      edits,
      deletes,
      merges,
      magicLayout,
      title,
      subtitle,
      captions,
      size,
      intro,
      outro,
      font_location,
      highlight_type,
      word_highlight_color,
      is_edited,
      template_id,
      caption_positions,
      caption_styles,
      hide_borders,
      text_highlight_color,
      ...restAssetMetadata
    }
  } = currentClipValue;
  const finalUpdateValue = { ...restAssetMetadata, ..._clipHistory.states[newHistoryIndex] };
  const captionsPositions = finalUpdateValue.caption_positions;
  if (captionsPositions) {
    const { x, y, width, height } = captionsPositions;

    EventBus.dispatch(CustomEvents.UpdateCaptionsPosition, {
      left: x,
      top: y,
      width,
      height
    });
  }

  updateClipMetadataWithId(clipId, finalUpdateValue);

  clipHistory.update(data => ({ ...data, [clipId]: { ...data[clipId], index: newHistoryIndex } }));
  saveClipChanges(clipId, false, false, (_clipHistory as ClipHistory).states[newHistoryIndex].is_edited);
}
