import { Popover } from '@headlessui/react';
import { memo, useCallback } from 'react';
import CaptionPreviewStyleOption from '../../CanvasPlayer/CanvasPlayerDraggableCaptions/CaptionsMenu/CaptionPreviewStyleOption';
import { CaptionStyleOption } from '../../CanvasPlayer/CanvasPlayerDraggableCaptions/CaptionsMenu/types';
import { getFontByName } from '../../SideBar/FontSelector/constants';
import { CAPTIONS_POPUP_OPTIONS } from './constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import {
  toggleCaptions,
  updateCaptionStyles,
  updateFontLocation,
  updateHighlightColor,
  updateHighlightType,
  updateKeyInMagicLayout,
  updateTextHighlightColor
} from '@/stores/clip';
import IconButton from '@/components/atoms/Button/IconButton';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { CaptionsPlacement } from '@/domains/asset';
import { updateCaptionsPlacementInRemotionConfig } from '@/App/remotion/libs';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

function ClipCustomizerCaptionsPopup({
  onEditCaptions,
  onTimeUpdate
}: {
  onEditCaptions: () => void;
  onTimeUpdate: (time: number, shouldPlay?: boolean) => void;
}) {
  const { clipId, clipData, playerStore } = useClipsContext();

  const applyOption = useCallback((val: CaptionStyleOption) => {
    const showCaptions = clipData.asset_metadata.subtitle;
    if (!showCaptions) {
      toggleCaptions(clipId, true);
    }
    updateHighlightType(clipId, val.highlight);
    updateCaptionStyles(clipId, {
      animation: val.style,
      ...(clipData.asset_metadata.size === 'PORTRAIT' && !showCaptions && { placement: CaptionsPlacement.Middle })
    });
    if (featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Remotion_Captions] && !showCaptions) {
      updateCaptionsPlacementInRemotionConfig({
        clipId,
        placement: clipData.asset_metadata.size === 'PORTRAIT' ? CaptionsPlacement.Middle : CaptionsPlacement.Bottom,
        caption_positions: clipData.asset_metadata.caption_positions
      });
    }

    if (val.textColor) updateKeyInMagicLayout(clipId, 'textColor', val.textColor);
    if (val.wordHighlightColor) updateHighlightColor(clipId, val.wordHighlightColor);
    if (val.textHighlightColor) updateTextHighlightColor(clipId, val.textHighlightColor);
    if (val.fontFamily) {
      const fontItem = getFontByName(val.fontFamily);
      if (fontItem) updateFontLocation(clipId, fontItem.url);
    }
    if (val.backgroundStyleColor) {
      updateCaptionStyles(clipId, { background_style_color: val.backgroundStyleColor });
    }
    if (clipData.asset_metadata.captions.length > 0 && !showCaptions && playerStore.paused) {
      const shouldSeekToFirstCaption = clipData.asset_metadata.captions[0].start_time > playerStore.currentTime;
      if (shouldSeekToFirstCaption) {
        onTimeUpdate(clipData.asset_metadata.captions[0].start_time + 0.1);
      }
    }
  }, []);

  function triggerEditCaptions() {
    EventBus.dispatch(CustomEvents.OpenEditCaptions);
    onEditCaptions();
    EventBus.dispatch(CustomEvents.OpenCaptionsMenu);
  }

  return (
    <Popover className="absolute right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-y-auto rounded-lg border bg-white shadow">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white px-4 py-3">
        <div className="text-lg font-medium">Captions</div>
        <IconButton
          icon="IconPencil"
          size="small"
          onClick={triggerEditCaptions}
          trackingId="captions-menu-edit-captions"
          content="Edit Captions"
        />
      </div>
      <div className="flex flex-col">
        {CAPTIONS_POPUP_OPTIONS.map((option, index) => (
          <div
            key={index}
            className="mx-2.5 my-[3px] cursor-pointer rounded-xl bg-slate-200 hover:bg-slate-300"
            onClick={() => applyOption(option)}
          >
            <div className="px-6 py-4">
              <CaptionPreviewStyleOption
                fontSize={18}
                hideDescription={true}
                clipMetadata={clipData.asset_metadata}
                styleOption={option}
              />
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );
}

export default memo(ClipCustomizerCaptionsPopup);
