import React, { useCallback, useEffect } from 'react';
import { AbsoluteFill, useCurrentFrame } from 'remotion';
import { Page, RemotionConfig } from '../../types';
import { SelectionOutline } from '../Outlines/SelectionOutline';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function CaptionsPageInternal({
  page,
  onOutlineRelease,
  canResizeCaptions,
  CaptionsMenu,
  canEditCaptions
}: Pick<RemotionConfig, 'onOutlineRelease' | 'CaptionsMenu' | 'canResizeCaptions' | 'canEditCaptions'> & {
  page: Page;
}) {
  const frame = useCurrentFrame();

  const [newPositions, setNewPositions] = React.useState({
    top: page.style.top,
    left: page.style.left,
    width: page.style.width,
    height: page.style.height
  });

  const updatePositions = useCallback(positions => {
    setNewPositions(p => ({ ...p, ...positions }));
  }, []);

  const onSelectionOutlineRelease = useCallback(
    positions => {
      if (
        positions.top === newPositions.top &&
        positions.left === newPositions.left &&
        positions.width === newPositions.width &&
        positions.height === newPositions.height
      )
        return;

      onOutlineRelease?.(positions);
    },
    [onOutlineRelease, newPositions]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UpdateCaptionsPosition, updatePositions);

    return () => {
      EventBus.off(CustomEvents.UpdateCaptionsPosition, eventListener);
    };
  }, [updatePositions]);

  return (
    <AbsoluteFill>
      <div style={{ ...page.style, ...newPositions }} id="captions-page-internal">
        <div style={page.groupedTokensStyle}>
          {page.tokens.map((t, index) => {
            const startRelativeToSequence = t.fromFrame - page.fromFrame;
            const endRelativeToSequence = t.toFrame - page.fromFrame;

            const active = startRelativeToSequence <= frame && endRelativeToSequence > frame;

            return (
              <div
                key={t.fromFrame}
                style={active ? page.activeTokenStyle : page.inactiveTokenStyle}
                id={'captions-page-token-' + index}
              >
                {t.text}
              </div>
            );
          })}
        </div>
      </div>
      {canEditCaptions && (
        <SelectionOutline
          positions={{ ...newPositions }}
          updatePositions={updatePositions}
          onOutlineRelease={onSelectionOutlineRelease}
          allowResize={canResizeCaptions}
          CaptionsMenu={CaptionsMenu}
          elementType="captions"
        />
      )}
    </AbsoluteFill>
  );
}
