import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { assetsChaptersPartialUpdate } from '@goldcast/api/content';
import { ChapterActionsMenuProps, ChapterType } from './ClipTimelineTypes';
import RowMenuItem from '@/Pages/Sessions/uiComponents/RowMenuItem';
import useTranscriptPage from '@/Pages/TranscriptPage/useTranscriptPage';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { MenuItemsLabelType, menuItems } from '@/Pages/Sessions/AssetsTable/AssetsTableUtils';
import { loadChapters } from '@/context/TranscriptContext/TranscriptContextUtils';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';

function ChapterActionsMenu({
  type,
  chapterStartTime,
  chapterEndTime,
  chapterId,
  onRenameChapter
}: ChapterActionsMenuProps) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const [isClipCreated, setIsClipCreated] = useState(false);
  const { saveClip } = useTranscriptPage();
  const { clipData } = useClipsContext();
  const { logger } = useAppContext();
  const navigate = useNavigate();

  const {
    isOpen: isRemoveChapterConfirmationOpen,
    openDialog: openRemoveChapterConfirmationDialog,
    closeDialog: closeRemoveChapterConfirmationDialog
  } = useDialog();

  useEffect(() => {
    if (isClipCreated) {
      navigate(`/${eventId}/${broadcastId}/clips`);
    }
  }, [isClipCreated]);

  const onCreateClip = useCallback(() => {
    setIsClipCreated(false);

    saveClip(
      {
        start: chapterStartTime,
        end: chapterEndTime,
        deletes: clipData.asset_metadata.deletes,
        edits: clipData.asset_metadata.edits
      },
      'USER'
    )
      .then(() => {
        setIsClipCreated(true);
        showSuccessToast('Clip created successfully');
      })
      .catch(err => {
        showErrorToast('Error while creating clip');
        logger.error('Error while creating clip', err);
      });
  }, [chapterStartTime, chapterEndTime, clipData, navigate, eventId, broadcastId]);

  const onRemoveClip = useCallback(() => {
    if (!chapterId || !broadcastId) return;

    assetsChaptersPartialUpdate({
      id: chapterId,
      body: {
        is_active: false
      }
    })
      .then(async () => {
        const updatedChapters = await loadChapters(broadcastId);
        EventBus.dispatch(CustomEvents.UpdateChapters, updatedChapters?.chapters);
        showSuccessToast('Chapter removed successfully');
      })
      .catch(err => {
        showErrorToast('Error while removing chapter');
        logger.error('Error while removing chapter', err);
      })
      .finally(() => {
        closeRemoveChapterConfirmationDialog();
      });
  }, [chapterId, broadcastId]);

  const onAction = useCallback(
    (actionType: MenuItemsLabelType) => {
      switch (actionType) {
        case 'Rename Chapter':
          onRenameChapter();
          break;
        case 'Create Clip':
          onCreateClip();
          break;
        case 'Remove Chapter':
          openRemoveChapterConfirmationDialog();
          break;
      }
    },
    [onCreateClip, onRenameChapter, openRemoveChapterConfirmationDialog]
  );
  return (
    <>
      <RowMenuItem
        variant={type === ChapterType.TIMELINE ? 'compact' : 'default'}
        content={null}
        menuItems={menuItems['CHAPTER']}
        menuContainerStyle={
          type === ChapterType.TIMELINE ? '!fixed -translate-x-[calc(100%-20px)] -translate-y-[calc(100%+24px)]' : ''
        }
        positionVariant={type === ChapterType.TIMELINE ? '' : 'top-left'}
        onAction={onAction}
        forceShow={false}
      />
      <ConfirmationDialog
        isOpen={isRemoveChapterConfirmationOpen}
        onClose={closeRemoveChapterConfirmationDialog}
        onConfirm={onRemoveClip}
        title="Remove Chapter"
        content="Are you sure you want to remove this chapter break in the video? You will not be able to revert this change."
        confirmLabel="Yes"
        cancelLabel="Cancel"
      />
    </>
  );
}

export default memo(ChapterActionsMenu);
