import { useEffect, useState, useSyncExternalStore } from 'react';
import { BrandTemplateListAPIQueryParams, brandTemplateList } from '@goldcast/api/content';
import { useQuery } from 'react-query';
import { IStringifyOptions } from 'qs';
import TemplatePreview from './TemplatePreview';
import { PreviewTemplate } from '../types';
import { mapBrandedTemplatesResponse } from './utils';
import TemplatesEmptyState from '../TemplatesSideBar/TemplatesEmptyState';
import TemplatesErrorState from '../TemplatesSideBar/TemplatesErrorState';
import { useActiveSpeakerMatchCondition } from '../../ClipPlayer/ClipCustomizer/useActiveSpeakerMatchCondition';
import Loader from '@/components/atoms/Loader';
import { SizeType } from '@/domains/asset';
import { applyTemplate, templateLoader } from '@/stores/clip';
import { classnames } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { isAudioContent } from '@/stores/core';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function TemplatesList({
  limit = 10,
  size,
  tags,
  showAll = true,
  queryParams = {},
  isInsidePopover = false,
  isFullRecordingEdit = false
}: {
  limit?: number;
  size?: SizeType;
  tags?: string;
  showAll?: boolean;
  queryParams?: Partial<BrandTemplateListAPIQueryParams>;
  isInsidePopover?: boolean;
  isFullRecordingEdit?: boolean;
}) {
  const { clipId } = useClipsContext();
  const [templates, setTemplates] = useState<PreviewTemplate[]>([]);
  const templateLoaderState = useSyncExternalStore(templateLoader.subscribe, templateLoader.getSnapshot);

  const {
    isFetching: loading,
    refetch,
    isError
  } = useQuery({
    queryKey: [size, tags, queryParams],
    queryFn: () =>
      brandTemplateList({
        queryParams: {
          limit,
          size,
          tags,
          layout: isFullRecordingEdit ? ['AUDIOGRAM', 'DEFAULT'] : isAudioContent() ? ['AUDIOGRAM'] : undefined,
          ...queryParams
        },
        queryParamsStringifyOptions: {
          arrayFormat: 'repeat'
        } as IStringifyOptions
      }),
    onSuccess: data => {
      const results = mapBrandedTemplatesResponse(clipId, data.results || []);
      setTemplates(showAll ? results : results.slice(0, 4));
    }
  });

  function reloadTemplates(params?: { template: PreviewTemplate }) {
    refetch().then(() => {
      if (params?.template) applyTemplate(clipId, params.template);
    });
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ReloadTemplates, reloadTemplates);
    return () => {
      EventBus.off(CustomEvents.ReloadTemplates, eventListener);
    };
  }, []);

  const { isSpeakerIdentificationMandatory } = useActiveSpeakerMatchCondition();

  return loading ? (
    <div className="flex h-80 items-center justify-center">
      <Loader />
    </div>
  ) : isError ? (
    <TemplatesErrorState />
  ) : !!templates.length ? (
    <div
      className={classnames(
        'mt-0.5 grid grid-cols-2 gap-3',
        templateLoaderState ? 'pointer-events-none cursor-progress opacity-70' : ''
      )}
    >
      {templates.map((template, index) => {
        return (
          <TemplatePreview
            template={template}
            key={template.id! + index}
            predefinedTooltipPosition={isInsidePopover ? (index % 2 !== 0 ? 'left' : 'right') : undefined}
            isSpeakerIdentificationMandatory={isSpeakerIdentificationMandatory}
          />
        );
      })}
    </div>
  ) : (
    <TemplatesEmptyState size={size} tag={tags} />
  );
}
