import { Sequence } from 'remotion';
import { Page, RemotionConfig } from '../../types';
import CaptionsPageInternal from './CaptionsPageInternal';

export default function CaptionsPage({
  page,
  onOutlineRelease,
  canResizeCaptions,
  canEditCaptions,
  CaptionsMenu,
  drift
}: Pick<RemotionConfig, 'onOutlineRelease' | 'CaptionsMenu' | 'canResizeCaptions' | 'drift' | 'canEditCaptions'> & {
  page: Page;
}) {
  const pageFromFrame = page.fromFrame;
  const pageToFrame = page.toFrame;

  const durationInFrames = pageToFrame - pageFromFrame;
  if (durationInFrames <= 0) {
    return null;
  }

  return (
    <Sequence from={pageFromFrame + drift} durationInFrames={durationInFrames} layout="none">
      {/* ! drift does not need to be passed internally as useCurrentFrame will give frames relative to sequence */}
      <CaptionsPageInternal
        page={page}
        onOutlineRelease={onOutlineRelease}
        canResizeCaptions={canResizeCaptions}
        CaptionsMenu={CaptionsMenu}
        canEditCaptions={canEditCaptions}
      />
    </Sequence>
  );
}
