import { BrandKitTemplateResponse, BrandTemplateCreateRequestBody } from '@goldcast/api/content';
import { getClipProcessingState, getClipUnavailableState } from '../../MagicLayout/magicLayoutUtils';
import { PreviewTemplate, TemplateDefault } from '../types';
import { currentClip } from '@/stores/clip';
import { ClipMetadata } from '@/domains/asset';

export function mapBrandedTemplatesResponse(
  clipId: string,
  responseResults: BrandKitTemplateResponse[]
): PreviewTemplate[] {
  const currentClipState = currentClip.getSnapshot()[clipId];
  return (responseResults || [])
    .map(item => {
      const [isUnavailable, message, reason] = getClipUnavailableState(item.layout, currentClipState);
      return {
        subtitle: item.config.subtitle,
        id: item.id,
        captions: currentClipState.asset_metadata.captions,
        layout: item.layout,
        size: item.size,
        intro: item.config.intro,
        outro: item.config.outro,
        title: item.title as string,
        position: item.config.position !== undefined ? item.config.position : 0.5,
        font_location: item.config.captions?.[0]?.style.regular_style.font_location,
        magicLayout: {
          backgroundColor: item.config.background_color,
          textColor: item.config.captions?.[0]?.style.regular_style.font_color,
          showSpeakerLabels: !!item.config.show_speaker_labels,
          backgroundImage: item.config.background_image
        },
        isUnavailable,
        message,
        reason,
        isProcessing: getClipProcessingState(item.layout, currentClipState),
        isDefault: !item.organization,
        tags: item.tags,
        highlight_type: item.config.captions?.[0]?.style.highlight_style?.highlight_type,
        word_highlight_color: item.config.captions?.[0]?.style.highlight_style?.background_color,
        text_highlight_color: item.config.captions?.[0]?.style.highlight_style?.font_color,
        createdAt: item.created_at,
        default: item.config.default as TemplateDefault,
        caption_positions: item.config.caption_positions,
        caption_styles: item.config.caption_styles,
        hide_borders: item.config.hide_borders !== undefined ? item.config.hide_borders : !item.config.subtitle
      };
    })
    .sort((a, b) => sortTemplates(a, b));
}

export function mapCurrentClipToTemplate(clipId): PreviewTemplate {
  const currentClipMetadata = currentClip.getSnapshot()[clipId].asset_metadata;
  return {
    subtitle: currentClipMetadata.subtitle,
    captions: currentClipMetadata.captions,
    layout: currentClipMetadata.layout,
    size: currentClipMetadata.size,
    intro: currentClipMetadata.intro,
    outro: currentClipMetadata.outro,
    font_location: currentClipMetadata.font_location,
    highlight_type: currentClipMetadata.highlight_type,
    word_highlight_color: currentClipMetadata.word_highlight_color,
    caption_positions: currentClipMetadata.caption_positions,
    caption_styles: currentClipMetadata.caption_styles,
    hide_borders: currentClipMetadata.hide_borders,
    text_highlight_color: currentClipMetadata.text_highlight_color,
    title: '',
    magicLayout: currentClipMetadata.magicLayout,
    isProcessing: false,
    isUnavailable: false,
    reason: null,
    message: null,
    isDefault: false
  };
}

export function getSaveTemplatePayload(template: PreviewTemplate, tags: string[]): BrandTemplateCreateRequestBody {
  const templateCaptions = [
    {
      style: {
        regular_style: {
          font_color: template.magicLayout?.textColor,
          font_location: template.font_location
        },
        highlight_style: {
          highlight_type: template.highlight_type,
          background_color: template.word_highlight_color,
          font_color: template.text_highlight_color
        }
      }
    }
  ];
  return {
    config: {
      background_color: template.magicLayout?.backgroundColor,
      background_image: template.magicLayout?.backgroundImage,
      show_speaker_labels: template.magicLayout?.showSpeakerLabels,
      captions: templateCaptions,
      intro: template.intro,
      outro: template.outro,
      subtitle: template.subtitle,
      caption_positions: template.caption_positions,
      caption_styles: template.caption_styles,
      hide_borders: template.hide_borders
    },
    layout: template.layout,
    size: template.size,
    tags: tags,
    title: template.title
  };
}

// If you are changing this, inform BE of the change as well
export const propertiesToCompare: (keyof Pick<
  ClipMetadata,
  | 'subtitle'
  | 'captions'
  | 'layout'
  | 'size'
  | 'intro'
  | 'outro'
  | 'magicLayout'
  | 'font_location'
  | 'highlight_type'
  | 'word_highlight_color'
  | 'text_highlight_color'
  | 'caption_positions'
  | 'caption_styles'
  | 'hide_borders'
>)[] = [
  'subtitle',
  'captions',
  'layout',
  'size',
  'intro',
  'outro',
  'magicLayout',
  'font_location',
  'highlight_type',
  'word_highlight_color',
  'text_highlight_color',
  'caption_positions',
  'caption_styles',
  'hide_borders'
];

function sortTemplates(a: PreviewTemplate, b: PreviewTemplate): number {
  const aIsProcessingOrUnavailable = a.isProcessing || a.isUnavailable;
  const bIsProcessingOrUnavailable = b.isProcessing || b.isUnavailable;
  // Check if only one of the objects has isProcessing and isUnavailable false
  if (aIsProcessingOrUnavailable !== bIsProcessingOrUnavailable) {
    return !a.isProcessing && !a.isUnavailable ? -1 : 1;
  } else if (aIsProcessingOrUnavailable || bIsProcessingOrUnavailable) {
    return a.layout === 'SPEAKER' ? -1 : 1;
  } else {
    if (a.position !== b.position) {
      return (a.position || 0) - (b.position || 0); // Sort by position
    } else {
      // If position is equal (for user-created ones)
      const createdAtComparison = new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
      return createdAtComparison !== 0 ? -createdAtComparison : 0;
    }
  }
}
