import { startTransition, useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { PlayerRef } from '@remotion/player';
import { useMainSectionRefReturn } from './ClipPlayerSectionsTypes';
import { getMainPlayerTime } from '../ClipPlayerControls/utils';
import { updatePlayerStoreWithId } from '@/stores/playerV2';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { isDeleted } from '@/context/TranscriptContext/TranscriptContextUtils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { ClipMetadata } from '@/domains/asset';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { editFullRecording } from '@/stores/editFullRecording';
import { FPS_24 } from '@/App/remotion/constants';

export default function useMainSectionRef(
  setCurrentSectionAndPlay,
  clipMetadata: ClipMetadata,
  playIndividualRecording
): useMainSectionRefReturn {
  const transcriptStore = useTranscriptContext();
  const { clipId } = useClipsContext();

  const mainSectionRef = useRef<HTMLVideoElement>(null);
  const remotionPlayerRef = useRef<PlayerRef>(null);
  const [secondaryPlayers, setSecondaryPlayers] = useState<React.RefObject<HTMLVideoElement>[]>([]);
  const [speakerImageRefs, setSpeakerImageRefs] = useState<React.RefObject<HTMLImageElement>[]>([]);

  const editFullRecordingStore = useSyncExternalStore(editFullRecording.subscribe, editFullRecording.getSnapshot);
  const isDragging = useMemo(() => editFullRecordingStore.isDragging, [editFullRecordingStore.isDragging]);

  useEffect(() => {
    if (mainSectionRef.current && !mainSectionRef.current.paused) {
      pauseMainSection();
      playMainSection();
    }
  }, [playIndividualRecording]);

  function toggleMutedState(mainPlayerMuted: boolean) {
    if (mainSectionRef.current) {
      mainSectionRef.current!.muted = mainPlayerMuted;
      secondaryPlayers.forEach(secondaryPlayer => {
        if (secondaryPlayer.current) secondaryPlayer.current.muted = !mainPlayerMuted;
      });
    }
  }

  const [mainSectionCurrentTime, setMainSectionCurrentTime] = useState(clipMetadata.start);
  const mainSectionDuration = useMemo(
    () => clipMetadata.end - clipMetadata.start,
    [clipMetadata.start, clipMetadata.end]
  );

  const [isMainSectionPaused, setIsMainSectionPaused] = useState(true);

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.visibilityState !== 'visible') {
        pauseMainSection();
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  });

  useEffect(() => {
    if (mainSectionRef.current?.duration !== mainSectionDuration) {
      updatePlayerStoreWithId(clipId, { duration: mainSectionDuration });
    }
    if (
      mainSectionRef.current &&
      (mainSectionRef.current.currentTime !== clipMetadata.start ||
        mainSectionRef.current.currentTime > clipMetadata.end)
    ) {
      mainSectionRef.current!.currentTime = clipMetadata.start;
      startTransition(() => {
        setMainSectionCurrentTime(clipMetadata.start);
      });
    }
  }, [mainSectionDuration, clipMetadata.start, clipMetadata.end]);

  useEffect(() => {
    let playerInterval;
    if (!isMainSectionPaused) {
      playerInterval = setInterval(() => {
        onMainSectionTimeUpdate();
      }, 30);
    }
    return () => {
      clearInterval(playerInterval);
    };
  }, [isMainSectionPaused]);

  const playMainSection = () => {
    toggleMutedState(playIndividualRecording);
    mainSectionRef.current?.play();
    secondaryPlayers.forEach(player => {
      if (player.current) {
        player.current!.play();
      }
    });

    if (remotionPlayerRef.current) {
      remotionPlayerRef.current.play();
    }

    setIsMainSectionPaused(false);
    updatePlayerStoreWithId(clipId, { paused: false });
  };

  const pauseMainSection = () => {
    toggleMutedState(playIndividualRecording);
    mainSectionRef.current?.pause();
    secondaryPlayers.forEach(player => {
      if (player.current) {
        player.current!.pause();
      }
    });

    if (remotionPlayerRef.current) {
      remotionPlayerRef.current.pause();
    }

    setIsMainSectionPaused(true);
    updatePlayerStoreWithId(clipId, { paused: true });
  };

  const onMainSectionTimeUpdate = useCallback(() => {
    if (!mainSectionRef.current) return;
    let currentTime = mainSectionRef.current!.currentTime;

    const adjustedPlayerTime = getMainPlayerTime(mainSectionRef.current, currentTime, clipMetadata.start, clipId);
    remotionPlayerRef.current?.seekTo(Math.max(0, Math.round(adjustedPlayerTime * FPS_24)));

    EventBus.dispatch(CustomEvents.ComputeFrame);

    const match = isDeleted(currentTime, clipId);
    // Skip to next non-deleted word, if that is not after clip end
    if (match && match < clipMetadata.end) {
      // added 0.001 to avoid rounding errors
      mainSectionRef.current!.currentTime = match + 0.001;
    }

    // Don't move to outro/intro if it's timeline dragging since it can go out of the current clip bounds
    if (!isDragging && (currentTime >= clipMetadata.end || (match && match >= clipMetadata.end))) {
      pauseMainSection();
      if (clipMetadata.outro) {
        setCurrentSectionAndPlay('outro', true);
        return;
      }
      if (clipMetadata.intro) {
        setCurrentSectionAndPlay('intro', false);
        return;
      }
      mainSectionRef.current!.currentTime = clipMetadata.start;
      currentTime = clipMetadata.start;
      EventBus.dispatch(CustomEvents.ClipEnded);
    }

    updatePlayerStoreWithId(clipId, { currentTime });

    setMainSectionCurrentTime(currentTime);
    secondaryPlayers.forEach(player => {
      updateSecondaryPlayerTime(player);
    });
  }, [
    clipMetadata.start,
    clipMetadata.end,
    clipMetadata.outro,
    clipMetadata.intro,
    clipId,
    isDragging,
    secondaryPlayers,
    pauseMainSection,
    setCurrentSectionAndPlay,
    updateSecondaryPlayerTime
  ]);

  function updateSecondaryPlayerTime(player: React.RefObject<HTMLVideoElement>) {
    if (player.current === null || !transcriptStore.speakersWithDetails?.[player.current?.id]) return;
    const time =
      mainSectionRef.current!.currentTime - transcriptStore.speakersWithDetails[player.current!.id].video.start;

    if (Math.abs(player.current!.currentTime - time) > 0.5) {
      player.current!.currentTime = time;
    }
  }

  return {
    mainSectionRef,
    mainSectionCurrentTime,
    mainSectionDuration,
    isMainSectionPaused,
    playMainSection,
    pauseMainSection,
    onMainSectionTimeUpdate,
    secondaryPlayers,
    setSecondaryPlayers,
    updateSecondaryPlayerTime,
    speakerImageRefs,
    setSpeakerImageRefs,
    remotionPlayerRef
  };
}
