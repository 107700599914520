import { SearchParagraphWord, TranscriptSelectionRange, TranscriptSelectionRanges } from '../types';
import { Word } from '@/domains/transcript';
import { Clip, ClipDeletes } from '@/domains/asset';
import {
  DeletedRange,
  EditedWord,
  saveClipChanges,
  updateClipCaptionsLoadingState,
  updateClipWithId
} from '@/stores/clip';
import { addDecimalIfNeeded } from '@/libs/utils';

/**
 *
 * @param selectionRanges ranges of words selected in transcript
 * @returns new deletes that are inside active words selected ranges
 */
export function getDeletesFromSelectedRanges(selectionRanges: TranscriptSelectionRanges | null): ClipDeletes {
  return selectionRanges
    ? selectionRanges?.active.reduce((acc: ClipDeletes, el: TranscriptSelectionRange) => {
        return {
          ...acc,
          [el.start]: {
            prev_end_time: el.start,
            next_start_time: el.end,
            bounds: [el.start, el.end]
          }
        };
      }, {})
    : {};
}

/**
 *
 * @param words that we need boundaries for
 * @returns first and last words on the words array
 */
export function getWordsBoundaries(words: Word[]): { firstWord: Word; lastWord: Word } {
  return {
    firstWord: words[0],
    lastWord: words[words.length - 1]
  };
}

/**
 *
 * @param firstSelectedWord from transcript selection
 * @param lastSelectedWord from transcript selection
 * @param clipDeletes from editing clip
 * @returns restoring & keepeing deletes based on selection. In case we select words from
 * multiple different deletes, all deletes would be part of restoring object
 */
export function getSelectionPartitionedDeletes(
  firstSelectedWord: Word,
  lastSelectedWord: Word,
  clipDeletes: ClipDeletes
): {
  restoring: ClipDeletes;
  keeping: ClipDeletes;
} {
  return Object.values(clipDeletes).reduce(
    (acc: { restoring: ClipDeletes; keeping: ClipDeletes }, deleteItem: DeletedRange) => {
      if (lastSelectedWord.end_time >= deleteItem.bounds[0] && firstSelectedWord.start_time <= deleteItem.bounds[1]) {
        acc.restoring[deleteItem.bounds[0]] = deleteItem;
      } else {
        acc.keeping[deleteItem.bounds[0]] = deleteItem;
      }
      return acc;
    },
    { restoring: {}, keeping: {} }
  );
}

/**
 * Merges entries in an object where the `bounds[1]` of one entry matches the `bounds[0]` of another.
 *
 * The function processes the `deletes` object by iterating through its entries,
 * merging consecutive entries that have matching bounds, and returns a new object with merged entries.
 *
 * @param {ClipDeletes} deletes - Deletes of a clip
 *
 * @returns {ClipDeletes} - A new object with merged entries. Keys are based on the `bounds[0]` values of the resulting entries.
 *
 * @example
 * const deletes = {
 *   "0.24": {
 *     "bounds": [0.24, 0.56],
 *     "prev_end_time": 0.24,
 *     "next_start_time": 0.56
 *   },
 *   "0.56": {
 *     "bounds": [0.56, 4],
 *     "prev_end_time": 0.56,
 *     "next_start_time": 4
 *   }
 * };
 *
 * const result = mergeDeletes(deletes);
 * // Output:
 * // {
 * //   "0.24": {
 * //     "bounds": [0.24, 4],
 * //     "prev_end_time": 0.24,
 * //     "next_start_time": 4
 * //   }
 * // }
 */
export function mergeDeletes(deletes: ClipDeletes): ClipDeletes {
  const deletesValues = Object.values(deletes);

  // Sort the entries by their bounds[0] to process them in order
  deletesValues.sort((a, b) => a.bounds[0] - b.bounds[0]);

  const merged: DeletedRange[] = [];

  for (let i = 0; i < deletesValues.length; i++) {
    const current = { ...deletesValues[i] };
    let j = i + 1;

    while (j < deletesValues.length && current.bounds[1] === deletesValues[j].bounds[0]) {
      current.bounds = [current.bounds[0], deletesValues[j].bounds[1]];
      current.next_start_time = deletesValues[j].next_start_time;
      j++;
    }

    merged.push(current);
    i = j - 1;
  }

  return merged.reduce((result, entry) => {
    result[entry.bounds[0]] = entry;
    return result;
  }, {});
}

/**
 * Updates the clip's word edits with a new word value for a specific word's start time.
 * In case of hide - value is sent as an empty string
 *
 * @param {Word} word - The word to be edited
 * @param {string} value - The new word content. In case of hide - value is sent as an empty string
 * @param {Clip} values - The clip to update
 * @returns {Clip} - A new clip object with the updated word edits in `asset_metadata.edits`.
 *
 * @example
 * const word = { start_time: "0.5", end_time: "1.0" };
 * const clip = { asset_metadata: { edits: {} } };
 * const updatedClip = editClipWord(word, "newValue", clip);
 * console.log(updatedClip.asset_metadata.edits["0.5"]);
 * // Output: { start_time: "0.5", end_time: "1.0", word: "newValue" }
 */
export function editClipWord(
  word: Word | SearchParagraphWord,
  value: string,
  clipData: Clip,
  addToMerges: boolean = false
): Clip {
  const editedWord: EditedWord = {
    start_time: word.start_time,
    end_time: word.end_time,
    word: value
  };
  const currentWordEdit = clipData.asset_metadata.edits?.[addDecimalIfNeeded(word.start_time)];
  const isHiddenWord = currentWordEdit && !currentWordEdit.word;
  return {
    ...clipData,
    asset_metadata: {
      ...clipData.asset_metadata,
      edits: {
        ...clipData.asset_metadata?.edits,
        // we identify hidden by `word: ''`, and if we override edits, it would make it non-deleted
        ...(isHiddenWord
          ? {}
          : {
              [addDecimalIfNeeded(word.start_time)]: {
                start_time: word.start_time,
                end_time: word.end_time,
                word: value
              }
            })
      },
      // Add to merges to cover the case edit -> hide. Without this it would revert to the original content
      merges: addToMerges
        ? (clipData.asset_metadata.merges ?? [])
            .filter(({ start_time }) => start_time !== editedWord.start_time)
            .concat(editedWord)
        : clipData.asset_metadata.merges ?? []
    }
  };
}

/**
 * Merges a word into a clip's metadata, updating the "merges" array and "edits" object.
 *
 * This function adds an `EditedWord` to the "merges" array in the clip's metadata,
 * when correcting transcript with multiple selected words
 *
 * @param {string} word - New corrected value
 * @param {Clip} clipData - The existing clip data to be updated.
 * @param {number} startTime - The start time correcting words
 * @param {number} endTime - The end time of correcting words
 * @returns {Clip} - A new `Clip` object with updated metadata.
 *
 * @example
 * const updatedClip = mergeClipWords("new word content", clipData, 1, 5);
 * console.log(updatedClip);
 * // Output:
 * // {
 * //   asset_metadata: {
 * //     merges: [
 * //       { start_time: 1, end_time: 5, word: "new word content" }
 * //     ],
 * //     edits: {
 * //       1: { start_time: 1, end_time: 5, word: "new word content" },
 * //     }
 * //   }
 * // }
 */
export function mergeClipWords(word: string, clipData: Clip, startTime: number, endTime: number): Clip {
  const editedWord: EditedWord = {
    start_time: startTime,
    end_time: endTime,
    word
  };
  return {
    ...clipData,
    asset_metadata: {
      ...clipData.asset_metadata,
      merges: (clipData.asset_metadata.merges ?? [])
        .filter(({ start_time }) => start_time !== editedWord.start_time)
        .filter(({ start_time }) => !(start_time >= editedWord.start_time && start_time <= editedWord.end_time))
        .concat(editedWord),
      edits: { ...clipData.asset_metadata.edits, [editedWord.start_time]: editedWord }
    }
  };
}

export function saveCorrectWordChanges(updatedClipValue: Clip, clipData: Clip) {
  updateClipCaptionsLoadingState(clipData.id, true);
  updateClipWithId(clipData.id, updatedClipValue, true);
  saveClipChanges(clipData.id, !!updatedClipValue, true, clipData.asset_metadata.is_edited);
}
